import SendIcon from '@mui/icons-material/Send';
import { Box, Button, styled, Typography } from '@mui/material';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';

import { useMessageInput } from '../../hooks/useMessageInput';

interface InitialScreenInputAreaProps {
  /** 크기 조절이 가능한 채팅창 너비 */
  resizableChatWindowWidth: number;
  /** AI 처리 중 여부 */
  isAiProcessing: boolean;
  /** AI에 메시지 전송하는 함수 */
  onSendMessageToAI: (response: string) => void;
}

const InitialScreenInputArea = ({
  resizableChatWindowWidth,
  ...messageInputProps
}: InitialScreenInputAreaProps) => {
  const { methods, inputMessage, MAX_CHARS, setIsComposing, onHandleSubmit, handleEnterKeyPress } =
    useMessageInput(messageInputProps);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <StyledFormProvider
        methods={methods}
        onSubmit={methods.handleSubmit(onHandleSubmit)}
        sx={{
          width: `${resizableChatWindowWidth / 1.5}px`,
        }}
      >
        <RHFTextField
          multiline // Shift + Enter 키 누르면 줄바꿈을 하게 해줌
          name="response"
          placeholder="궁금한 사항을 구체적으로 작성해주세요."
          variant="standard"
          inputProps={{ maxLength: MAX_CHARS }}
          inputRef={(textFieldInput) => textFieldInput && textFieldInput.focus()} // <TextField /> 가 렌더링 되었을 때 자동으로 텍스트 필드에 포커싱하기
          sx={{
            '& .MuiInputBase-root': {
              overflow: 'auto',
            },
            '& .MuiInputBase-input': {
              height: '100% !important', // 입력 영역이 전체 높이를 차지하도록 설정
              overflowY: 'auto !important', // 세로 스크롤 활성화
            },
            // --------------------------------------------------------------------------
            /** TextField의 기본적으로 있는 검은색 밑줄 제거 */
            '& .MuiInput-underline:before': {
              borderBottom: 'none',
            },
            '& .MuiInput-underline:after': {
              borderBottom: 'none',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottom: 'none',
            },
            // --------------------------------------------------------------------------
          }}
          onKeyDown={(event) => handleEnterKeyPress(event, methods.handleSubmit)}
          onCompositionStart={() => setIsComposing(true)}
          onCompositionEnd={() => setIsComposing(false)}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="outlined"
            disabled={inputMessage.length === 0 || inputMessage.length > MAX_CHARS}
            onClick={() => {
              onHandleSubmit({ response: methods.getValues().response });
            }}
          >
            <SendIcon sx={{ fontSize: 20 }} />
          </Button>
          <Typography variant="caption" sx={{ mt: 1, display: 'block', textAlign: 'center' }}>
            {inputMessage.length} / {MAX_CHARS}
          </Typography>
        </Box>
      </StyledFormProvider>
    </Box>
  );
};

const StyledFormProvider = styled(FormProvider)(() => ({
  border: '2px solid #0F85FB',
  borderRadius: '8px',
  padding: '20px',
  display: 'flex',
}));

export default InitialScreenInputArea;
