import 'cal-sans';
import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import 'iframe-resizer/js/iframeResizer.contentWindow';
import './styles/index.css';
import './styles/editor.css';

import { useRef } from 'react';
import { Editor, EditorContent } from '@tiptap/react';

import { ContentItemMenu, LinkMenu, TextMenu } from './components/menus';
import { ColumnsMenu } from './components/menus/ColumnsMenu';
import TableColumnMenu from './components/menus/TableColumn';
import TableRowMenu from './components/menus/TableRow';
import ImageBlockLightbox from './extensions/ImageBlock/components/ImageBlockLightbox';
import ImageBlockMenu from './extensions/ImageBlock/components/ImageBlockMenu';

interface BlockEditorProps {
  editor: Editor | null;
}

export const BlockEditor = ({ editor, ...props }: BlockEditorProps) => {
  const menuContainerRef = useRef(null);
  if (!editor) return null;

  return (
    <div ref={menuContainerRef} style={{ display: 'flex', height: '100%' }} {...props}>
      <div className="relative flex flex-col flex-1 h-full overflow-hidden">
        <EditorContent editor={editor} className="flex-1 overflow-y-auto" />
        <ContentItemMenu editor={editor} />
        <TextMenu editor={editor} />
        <LinkMenu editor={editor} appendTo={menuContainerRef} />
        <ColumnsMenu editor={editor} appendTo={menuContainerRef} />
        <TableRowMenu editor={editor} appendTo={menuContainerRef} />
        <TableColumnMenu editor={editor} appendTo={menuContainerRef} />
        <ImageBlockMenu editor={editor} appendTo={menuContainerRef} />
        <ImageBlockLightbox editor={editor} />
      </div>
    </div>
  );
};

export default BlockEditor;
