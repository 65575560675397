import { forwardRef } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ChatbotFAQ } from 'src/@types/apiResponseTypes';

import useToggleDrawer from '../../hooks/useToggleDrawer';
import { ChatList, CurrentChatRoomIdState, OnDeleteChatRoom } from '../../utils/types';

import ChatListItemBox from './ChatListItemBox';

interface ChatsSidebarProps {
  currentChatRoomIdState: CurrentChatRoomIdState;
  chatRoomsCollection: {
    chatRooms: ChatList[];
    innerwaveGuideChatRooms?: ChatbotFAQ[];
  };
  onOpenFeedbackModal: () => void;
  onDeleteChatRoom: OnDeleteChatRoom;
}

/**
 * 채팅 사이드바의 메인 컴포넌트
 * - 일반 채팅 목록과 FAQ 목록 관리
 * - 피드백 버튼 제공
 * - 모바일/데스크톱 레이아웃 대응
 */
const ChatSidebar = forwardRef<HTMLLIElement, ChatsSidebarProps>(
  (
    { currentChatRoomIdState, chatRoomsCollection, onOpenFeedbackModal, onDeleteChatRoom },
    lastChatElementRef,
  ) => {
    const { chatRooms, innerwaveGuideChatRooms } = chatRoomsCollection;

    const exposingFaqs = innerwaveGuideChatRooms?.filter((guide) => guide.use_yn);

    const toggleDrawer = useToggleDrawer();

    return (
      <Stack
        flex={1}
        justifyContent={'space-between'}
        sx={{
          width: '100%',
          paddingX: 0.5,
        }}
      >
        <Stack justifyContent={'flex-start'} flex={1}>
          <ChatListItemBox
            ref={lastChatElementRef}
            subtitle={{
              text: 'CHAT LIST',
              icon: <DeleteOutlineIcon sx={{ color: '#FFF8', fontSize: 18 }} />,
            }}
            chatRooms={chatRooms}
            currentChatRoomIdState={currentChatRoomIdState}
            onCloseDrawer={toggleDrawer.handleDrawerClose}
            onDeleteChatRoom={onDeleteChatRoom}
          />
          <ChatListItemBox
            subtitle={{ text: 'INNERWAVE GUIDE' }}
            chatRooms={exposingFaqs || []}
            currentChatRoomIdState={currentChatRoomIdState}
            onCloseDrawer={toggleDrawer.handleDrawerClose}
          />
          <Box
            sx={{
              flexGrow: 1,
              width: '96%',
              textAlign: 'center',
              flexDirection: 'column',
              padding: '15px 10px',
              height: '80px',
              pb: 10,
            }}
          >
            <Button
              sx={{
                width: '100%',
                height: '40px',
                border: '1px solid #FFF8',
                borderRadius: '20px',
                color: '#FFF8',
                mb: 1,
                '&:hover': {
                  borderColor: '#FFF',
                  color: '#FFF',
                },
              }}
            >
              <Typography variant="button" onClick={onOpenFeedbackModal}>
                의견 보내기
              </Typography>
            </Button>
            <Typography variant="body2" sx={{ fontSize: '0.8rem', color: '#FFF8' }}>
              ESC - 닫기
            </Typography>
          </Box>
        </Stack>
      </Stack>
    );
  },
);
export default ChatSidebar;
