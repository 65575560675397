import {
  IReportTemplateSearchParams,
  IWorkReportSearchParams,
  TMeetingReportFormData,
  TReportTemplateFormData,
  TSearchParams,
  TWorkReportFormData,
} from 'src/@types';
import {
  IAttachment,
  IReport,
  IReportComment,
  IReportTemplate,
  IWorkDashboard,
  IWorkReportSearchFilterList,
} from 'src/@types/apiResponseTypes';
import { contentImageStore } from 'src/models';
import { DOCU_TYPE_CODE } from 'src/static/constants';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

const convertReportInfoToFormData = (
  reportInfo: TMeetingReportFormData | TWorkReportFormData,
): FormData => {
  const formData = new FormData();

  // 파일 추가
  reportInfo.files.forEach((file) => {
    formData.append('files', file);
  });

  // report 정보 추가
  formData.append('docuTypeCd', reportInfo.docuTypeCd.toString());
  formData.append('title', reportInfo.title);

  // 문서 유형이 회의록인 경우
  if (reportInfo.docuTypeCd === DOCU_TYPE_CODE.MEETING && 'meeting' in reportInfo) {
    // TODO: metTitle 없애도 될듯
    formData.append('content', reportInfo.content);
    formData.append('meeting.metTitle', 'temp title');
    formData.append('meeting.metStartDt', String(reportInfo.meeting.meetingTime[0].getTime()));
    formData.append('meeting.metEndDt', String(reportInfo.meeting.meetingTime[1].getTime()));

    reportInfo.tagList.forEach((tag, index) => {
      formData.append(`tagList[${index}].tagContent`, tag);
    });

    reportInfo.meeting.members.forEach((member, index) => {
      formData.append(`meeting.members[${index}].userSid`, String(member.userSid));
    });

    reportInfo.meeting.extrnList.forEach((extrn, index) => {
      formData.append(`meeting.extrnList[${index}].extrnName`, String(extrn.extrnName));
    });
  } else if ('contents' in reportInfo) {
    // 제출여부 추가
    formData.append('submitYn', String(reportInfo.submitYn));

    // 업무보고인 경우 프로젝트별 콘텐츠 추가
    reportInfo.contents.forEach((content, index) => {
      formData.append(`contents[${index}].projectSid`, String(content.projectSid));
      formData.append(`contents[${index}].content`, content.content);
      formData.append(`contents[${index}].content2`, content.content2);
      formData.append(`contents[${index}].issueContent`, content.issueContent ?? '');
    });

    // 보고유형별 보고일 추가
    if (reportInfo.docuTypeCd !== DOCU_TYPE_CODE.PROJECT) {
      formData.append('year', String(reportInfo.year));
      formData.append('month', String(reportInfo.month));
      formData.append('week', String(reportInfo.week));
      formData.append('day', String(reportInfo.day));
    }
  }
  return formData;
};

const SUB_URL = '/v1/report';

/**
 * 보고서 목록 조회
 */
export const getReports = async (params?: IWorkReportSearchParams) => {
  try {
    const url = SUB_URL;

    const response = await instance.get<TApiResponseData<IReport[]>>(url, {
      params,
    });
    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 단건 조회
 */
export const getReport = async (reportSid: number) => {
  try {
    const url = `${SUB_URL}/${reportSid}`;

    const response = await instance.get<TApiResponseData<IReport>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 등록
 * reportInfo: 보고서 등록 정보
 */
export const createReport = async (reportInfo: TMeetingReportFormData | TWorkReportFormData) => {
  try {
    const url = SUB_URL;
    const formData = convertReportInfoToFormData(reportInfo);

    const response = await instance.post<TApiResponseData<IReport>>(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 수정
 * reportSid: 보고서 ID
 * reportInfo: 보고서 등록 정보
 */
export const updateReport = async (
  reportSid: number,
  reportInfo: TMeetingReportFormData | TWorkReportFormData,
) => {
  try {
    const url = `${SUB_URL}/${reportSid}`;
    const formData = convertReportInfoToFormData(reportInfo);

    const response = await instance.put<TApiResponseData<IReport>>(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 삭제
 * reportSid: 보고서 ID
 */
export const deleteReport = async (reportSid: number) => {
  try {
    const url = `${SUB_URL}/${reportSid}`;

    const response = await instance.delete<TApiResponseData<any>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 첨부파일 목록 조회
 */
export const getReportAttachments = async (params: TSearchParams) => {
  try {
    const url = `/v1/report/file`;

    const response = await instance.get<TApiResponseData<IAttachment[]>>(url, {
      params,
    });

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 첨부파일 삭제
 * attachment: IAttachment
 */
export const deleteFile = async (attachment: IAttachment) => {
  try {
    const url = `${SUB_URL}/${attachment.reportSid}/file/${attachment.fileSid}`;
    const response = await instance.delete<TApiResponseData<any>>(url);

    if (response.data.resultCode === 'S') {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 템플릿 목록 조회
 */
export const getReportTemplates = async (params: IReportTemplateSearchParams) => {
  try {
    const url = `/v1/templates`;

    const response = await instance.get<TApiResponseData<IReportTemplate[]>>(url, {
      params: {
        ...params,
      },
    });

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 템플릿 단건 조회
 */
export const getReportTemplate = async (tmplSid: number) => {
  try {
    const url = `/v1/template/${tmplSid}`;

    const response = await instance.get<TApiResponseData<IReportTemplate>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 템플릿 등록
 * @param reportTemplateInfo: 보고서 템플릿 등록 정보
 */
export const createReportTemplate = async (reportTemplateInfo: TReportTemplateFormData) => {
  try {
    const url = `/v1/template`;
    // TODO: tmplNme, docuTypeCd 없애자
    const payload = reportTemplateInfo;

    const response = await instance.post<TApiResponseData<IReportTemplate>>(url, payload);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 템플릿 수정
 * @param tmplSid: 보고서 템플릿 ID
 * @param reportTemplateInfo: 보고서 템플릿 등록 정보
 */
export const updateReportTemplate = async (
  tmplSid: number,
  reportTemplateInfo: TReportTemplateFormData,
) => {
  try {
    const url = `/v1/template/${tmplSid}`;
    // TODO: tmplNme, docuTypeCd 없애자
    const payload = reportTemplateInfo;

    const response = await instance.put<TApiResponseData<IReportTemplate>>(url, payload);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 템플릿 삭제
 * @param tmplSid: 보고서 템플릿 ID
 */
export const deleteReportTemplate = async (tmplSid: number) => {
  try {
    const url = `/v1/template/${tmplSid}`;

    const response = await instance.delete<TApiResponseData<IReportTemplate>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 내부 컨텐츠 이미지 업로드
 * files: File[]
 * @returns file path array | undefined
 */
export const uploadContentImage = async (files: File[]) => {
  try {
    const url = `${SUB_URL}/contents/files`;
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file);
    });

    const response = await instance.post<TApiResponseData<string[]>>(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      const paths = response.data.data;
      contentImageStore.setProp('paths', [...contentImageStore.paths, ...paths]);
      return paths;
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 내부 컨텐츠 이미지 업로드
 * deleteContentsPaths: string[]
 * @returns success count | undefined
 */
export const deleteContentImage = async (deleteContentsPaths: string[]) => {
  try {
    const url = `${SUB_URL}/contents/files`;
    const payload = {
      deleteContentsPathList: deleteContentsPaths,
    };

    const response = await instance.put<TApiResponseData<number>>(url, payload);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      // contentImageStore paths 초기화
      contentImageStore.clear();
      // 사진 삭제 성공한 사진 수 반환
      return response.data.data;
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 업무보고 검색 필터 리스트 조회
 * @returns
 */

export const getWorkReportSearchFilterList = async () => {
  try {
    const url = `${SUB_URL}/filter`;

    const response = await instance.get<TApiResponseData<IWorkReportSearchFilterList>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 댓글 조회
 * @param reportSid: 보고서 ID
 * @returns IReportComment[] | undefined
 */

export const getReportComments = async (reportSid: number) => {
  try {
    const url = `${SUB_URL}/${reportSid}/comment`;

    const response = await instance.get<TApiResponseData<IReportComment[]>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 댓글 등록
 * @param reportSid: 보고서 ID
 * @param comment: 댓글 내용
 * @param pcommentSid: 부모 댓글 ID
 * @returns IReportComment | undefined
 */

export const createReportComment = async (
  reportSid: number,
  comment: string,
  pcommentSid?: number,
) => {
  try {
    const url = `${SUB_URL}/${reportSid}/comment`;
    const payload = {
      comment,
      ...(pcommentSid && { pcommentSid }),
    };
    const response = await instance.post<TApiResponseData<IReportComment>>(url, payload);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 댓글 수정
 * @param reportSid: 보고서 ID
 * @param commentSid: 댓글 ID
 * @param comment: 댓글 내용
 * @returns IReportComment | undefined
 */

export const updateReportComment = async (
  reportSid: number,
  commentSid: number,
  comment: string,
) => {
  try {
    const url = `${SUB_URL}/${reportSid}/comment/${commentSid}`;
    const payload = {
      comment,
    };
    const response = await instance.put<TApiResponseData<IReportComment>>(url, payload);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 댓글 삭제
 * @param reportSid: 보고서 ID
 * @param commentSid: 댓글 ID
 * @param comment: 댓글 내용
 * @returns IReportComment | undefined
 */

export const deleteReportComment = async (reportSid: number, commentSid: number) => {
  try {
    const url = `${SUB_URL}/${reportSid}/comment/${commentSid}`;
    const response = await instance.delete<TApiResponseData<IReportComment>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 본부 업무 현황(보고서) 조회
 */
export const getHeadquarterWorkReports = async (params: IWorkReportSearchParams) => {
  try {
    const url = `${SUB_URL}/dashboard`;

    const response = await instance.get<TApiResponseData<IWorkDashboard>>(url, {
      params,
    });
    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.data.pagination,
      };
    }

    return {
      errorMessage:
        response.data.resultCode === 'F' ? response.data.errorMessage : '목록 조회를 실패했습니다.',
    };
  } catch (error) {
    console.error(error);
  }
};

/**
 * 팀 업무 현황(보고서) 조회
 */
export const getTeamWorkReports = async (params: IWorkReportSearchParams) => {
  try {
    const url = `${SUB_URL}/dashboard/team-leader`;

    const response = await instance.get<TApiResponseData<IWorkDashboard>>(url, {
      params,
    });
    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }

    return {
      errorMessage:
        response.data.resultCode === 'F' ? response.data.errorMessage : '목록 조회를 실패했습니다.',
    };
  } catch (error) {
    console.error(error);
  }
};

/**
 * 팀원 업무 현황(보고서) 조회
 */
export const getTeamMemberWorkReports = async (params: IWorkReportSearchParams) => {
  try {
    const url = `${SUB_URL}/dashboard/team-member`;

    const response = await instance.get<TApiResponseData<IWorkDashboard>>(url, {
      params,
    });
    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }

    return {
      errorMessage:
        response.data.resultCode === 'F' ? response.data.errorMessage : '목록 조회를 실패했습니다.',
    };
  } catch (error) {
    console.error(error);
  }
};
