import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { AddChatbotFAQPayload, ChatbotFAQ } from 'src/@types/apiResponseTypes';
import { chatbotApi } from 'src/api';

// FAQ 목록 조회 요청 파라미터 타입

function useFAQResponse() {
  // 현재 선택된 FAQ 상태 관리
  const [faq, setFaq] = useState<ChatbotFAQ | undefined>(undefined);
  // 스낵바 알림을 위한 훅
  const { enqueueSnackbar } = useSnackbar();

  // FAQ 목록을 페이지네이션과 검색어로 조회하는 함수
  const getFAQs = async ({
    page = 1,
    size = 25,
    keyword,
  }: {
    keyword?: string;
    page: number;
    size: number;
  }) => {
    try {
      const result = await chatbotApi.getFAQs({ page, size, keyword });
      if (result) {
        return result;
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 조회수 기준 상위 5개 FAQ를 조회하는 함수
  const getTop5ViewFAQs = async () => {
    try {
      const faqs = await chatbotApi.getTop5ViewFAQs();
      if (faqs) {
        return faqs;
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 특정 FAQ의 상세 정보를 조회하는 함수
  const findFAQ = async (faq_id: number) => {
    try {
      const data = await chatbotApi.findFAQ(faq_id);
      if (data) {
        setFaq(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 새로운 FAQ를 추가하는 함수
  const addFAQ = async (requestBody: AddChatbotFAQPayload) => {
    try {
      const response = await chatbotApi.addFAQ(requestBody);
      // FAQ 등록 성공/실패에 따른 스낵바 알림 표시
      if (response) {
        enqueueSnackbar('FAQ가 등록되었습니다.', { variant: 'success' });
      } else {
        enqueueSnackbar('FAQ가 등록에 실패하였습니다.', { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // FAQ 관련 상태와 함수들을 반환
  return { getFAQs, getTop5ViewFAQs, addFAQ, faq, findFAQ, setFaq };
}

export default useFAQResponse;
