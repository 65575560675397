import { Instance, SnapshotOut, types } from 'mobx-state-tree';

const DepartmentModelProps = {
  deptSid: types.number,
  deptPsid: types.maybeNull(types.number),
  deptNm: types.string,
  deptDescr: types.maybeNull(types.string),
  // TODO: 추후 삭제 예정
  deptPnm: types.maybeNull(types.string),
  pnm: types.maybeNull(types.string),
  ordr: types.maybeNull(types.number),
  regDt: types.number,
  updDt: types.number,
};

/**
 * # Department
 * - 부서 모델
 * - [deptSid]: 부서 고유번호
 * - [deptPsid]: 상위 부서 고유번호
 * - [deptNm]: 부서명
 * - [deptDescr]: 부서 설명
 * - [pnm]: 상위 부서명
 * - [ordr]: 순서
 * - [regDt]: 등록일
 * - [updDt]: 수정일
 */
export const DepartmentModel = types.model('DepartmentModel', DepartmentModelProps);
type TDepartmentModel = Instance<typeof DepartmentModel>;
type TDepartmentModelSnapshot = SnapshotOut<typeof DepartmentModel>;
export interface IDepartmentModel extends TDepartmentModel {}
export type TDepartmentModelKeys = keyof TDepartmentModelSnapshot & string;
export interface IDepartmentModelSnapshot extends TDepartmentModelSnapshot {}
