import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { AlertStore, IAlertStore } from '../alert-store/AlertStore';
import { AuthStore, AuthStoreModel } from '../auth-store/auth-store';
import { CommonCodeStore } from '../common-code-store/CommonCodeStore';
import { DepartmentStoreModel, IDepartmentStore } from '../department-store/DepartmentStore';
import { withEnvironment } from '../extensions/withEnvironment';
import { ILoadingStore, LoadingStore } from '../loading-store/LoadingStore';

export const RootStoreModel = types
  .model('RootStore')
  .props({
    alertStore: types.optional(AlertStore, {} as IAlertStore),
    loadingStore: types.optional(LoadingStore, {} as ILoadingStore),
    commonCodeStore: types.optional(CommonCodeStore, {
      commonCodes: [],
      dynamicCodes: {},
    }),
    departmentStore: types.optional(DepartmentStoreModel, {} as IDepartmentStore),
    authStore: types.optional(AuthStoreModel, {} as AuthStore),
  })
  .extend(withEnvironment);

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
