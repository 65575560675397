import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Box, styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ChatbotFAQ } from 'src/@types/apiResponseTypes';
import { useStores } from 'src/models';
import ChatbotFeedbackFormModal from 'src/screens/admin-management/chat-bot/feedback/ChatbotFeedbackFormModal';

import ChatSidebar from './components/chat-sidebar';
import DrawerForMobile from './components/chat-sidebar/DrawerForMobile';
import SidebarForDesktop from './components/chat-sidebar/SidebarForDesktop';
import ChatWindow from './components/chat-window';
import NewChatButton from './components/new-chat-button/NewChatButton';
import { useChatActionsContext, useChatStateContext } from './context/ChatContext';
import useChatResponse from './hooks/useChatResponse';
import useFAQResponse from './hooks/useFAQResponse';
import useFeedbackForm from './hooks/useFeedbackForm';
import useInfiniteScroll from './hooks/useInfiniteScroll';
import useResizeContainer from './hooks/useResizeContainer';
import useToggleDrawer from './hooks/useToggleDrawer';
import { isMobile } from './utils/common';
import { ChatList, CurrentChatRoomIdState, TSetState } from './utils/types';

/**
 * AI 챗봇의 메인 컴포넌트
 * - 채팅 사이드바와 채팅 창을 관리
 * - 모바일/데스크톱 레이아웃 처리
 * - 채팅 상태 및 FAQ 관리
 */

interface SidebarRef {
  chatListState: {
    chatList: ChatList[];
    setChatList: TSetState<ChatList[]>;
  };
}

const MIN_WIDTH = 800;

function AiChatbot() {
  const { authStore } = useStores();

  const userSid = authStore.user?.userSid;

  const {
    isInitialChatPage,
    isChatWindowOpened,
    isStreaming,
    aiResponse,
    aiResponseStatus,
    conversations,
    currentChatRoomId,
    sidebarRef,
  } = useChatStateContext();
  const {
    setConversations,
    sanitizeChatRoomState,
    setCurrentChatRoomId,
    setIsChatWindowOpened,
    setIsInitialChatPage,
    sendMessageToAI,
    findChatHistory,
    moveToInitialChatPage,
  } = useChatActionsContext();

  /** @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */
  /** FAQ State & Actions */
  const [chatFaqs, setChatFaqs] = useState<ChatbotFAQ[]>([]);
  const { getFAQs, getTop5ViewFAQs, findFAQ, faq, setFaq } = useFAQResponse();

  const handleGetFaqs = async () => {
    try {
      const result = await getFAQs({ page: 1, size: 25 });
      if (result) {
        setChatFaqs(result.faqs);
      }
    } catch (error) {
      console.error(error);
    }
  };
  /** Top 5 Views of FAQ  */
  const [topFiveViewFaqs, setTopFiveViewFaqs] = useState<ChatbotFAQ[]>([]);
  const handleGetTopFiveViewFaqs = async () => {
    try {
      const top5Faqs = await getTop5ViewFAQs();
      if (top5Faqs) {
        setTopFiveViewFaqs(top5Faqs);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const toggleDrawer = useToggleDrawer();

  const { containerRef, resizableChatWindowWidth, handleResizeWidth } = useResizeContainer({
    minWidth: MIN_WIDTH,
    maxWidth: MIN_WIDTH * 2,
  });

  const onAddNewChatRoom = moveToInitialChatPage;

  const openChatbotWindowCustomEvent = useCallback(() => {
    setIsChatWindowOpened(true);
    moveToInitialChatPage();
  }, []);

  const onSetCurrentRoomId = {
    onSetCurrentChatRoomId: async (chat_id: string) => {
      try {
        if (currentChatRoomId !== chat_id) {
          await findChatHistory({ chat_id });
          setCurrentChatRoomId(chat_id);
          setFaq(undefined);
          setIsInitialChatPage(false);
        }
      } catch (error) {
        console.error(error);
      }
    },
    onSetCurrentFAQRoomId: async (faq_id: number) => {
      try {
        if (currentChatRoomId !== faq_id.toString()) {
          await findFAQ(faq_id);
          setCurrentChatRoomId(faq_id.toString());
          setConversations(undefined);
        }
      } catch (error) {
        console.error(error);
      }
    },
  };

  const loadFaqs = async () => {
    try {
      await Promise.all([handleGetFaqs(), handleGetTopFiveViewFaqs()]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // 챗봇 화면이 열려 있을 때에만 ESC 키 감지
      if (isChatWindowOpened && e.key === 'Escape') {
        e.preventDefault();
        setIsChatWindowOpened(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isChatWindowOpened]);

  useEffect(() => {
    loadFaqs();
  }, []);

  useEffect(() => {
    window.addEventListener('chatWindowCloseEvent', openChatbotWindowCustomEvent as EventListener);

    return () => {
      window.removeEventListener(
        'chatWindowCloseEvent',
        openChatbotWindowCustomEvent as EventListener,
      );
    };
  }, [openChatbotWindowCustomEvent]);

  const isAiProcessing = aiResponseStatus.isAITyping || isStreaming;

  if (!isChatWindowOpened) {
    return null;
  }
  return (
    <StyledContainer
      ref={containerRef}
      sx={{ display: 'flex', flexDirection: 'row', width: resizableChatWindowWidth }}
    >
      {isMobile() ? (
        <DrawerForMobile
          toggleDrawer={toggleDrawer}
          isAiProcessing={isAiProcessing}
          onAddNewChatRoom={onAddNewChatRoom}
        >
          <Sidebar
            ref={sidebarRef}
            userSid={userSid}
            currentChatRoomIdState={{ currentChatRoomId, onSetCurrentRoomId }}
            chatFaqs={chatFaqs}
            moveToInitialChatPage={moveToInitialChatPage}
            onSanitizeChatRoomState={sanitizeChatRoomState}
          />
        </DrawerForMobile>
      ) : (
        <SidebarForDesktop
          resizableChatWindowWidth={resizableChatWindowWidth}
          newButtonComp={<NewChatButton disabled={isAiProcessing} onClick={onAddNewChatRoom} />}
          onMouseDown={handleResizeWidth}
        >
          <Sidebar
            ref={sidebarRef}
            userSid={userSid}
            currentChatRoomIdState={{ currentChatRoomId, onSetCurrentRoomId }}
            chatFaqs={chatFaqs}
            moveToInitialChatPage={moveToInitialChatPage}
            onSanitizeChatRoomState={sanitizeChatRoomState}
          />
        </SidebarForDesktop>
      )}
      <ChatWindow
        isInitialChatPage={isInitialChatPage}
        isStreaming={isStreaming}
        aiResponse={aiResponse}
        aiResponseStatus={aiResponseStatus}
        chatFaqs={topFiveViewFaqs}
        chatRoom={conversations || faq || []}
        currentChatRoomId={currentChatRoomId}
        resizableChatWindowWidth={resizableChatWindowWidth}
        onAddNewChatRoom={onAddNewChatRoom}
        onSendMessageToAI={(response: string) => {
          sendMessageToAI(response, userSid);
        }}
        onClose={() => setIsChatWindowOpened(false)}
      />
    </StyledContainer>
  );
}

interface SidebarProps {
  userSid?: number;
  currentChatRoomIdState: CurrentChatRoomIdState;
  chatFaqs: ChatbotFAQ[];
  moveToInitialChatPage: () => void;
  onSanitizeChatRoomState: () => void;
}

const Sidebar = forwardRef<SidebarRef, SidebarProps>(
  (
    {
      userSid,
      currentChatRoomIdState,
      chatFaqs,
      moveToInitialChatPage,
      onSanitizeChatRoomState,
    }: SidebarProps,
    ref,
  ) => {
    const [chatList, setChatList] = useState<ChatList[]>([]);

    const { getChatList, onDeleteChatRoom } = useChatResponse();
    const { deleteChat, deleteAllChats } = onDeleteChatRoom;

    const { feedbackFormModelInfo, handleAddFeedback } = useFeedbackForm();

    const { lastElementRef } = useInfiniteScroll<ChatList>(getChatList, {
      getPageParam: (page) => (userSid ? { user_id: userSid, page, size: 10 } : null),
      onSuccess: (newData) => {
        setChatList(newData);
      },
    });

    const deleteSingleChatRoom = async (id: string) => {
      try {
        if (userSid) {
          await deleteChat({ chat_id: id });
          if (chatList.length > 0) {
            // 채팅방 삭제 API 요청 후 삭제 처리 된 후의 채팅방 UI 업데이트
            const chatList = await getChatList({ user_id: userSid });
            setChatList(chatList);
            // 지운 채팅방 아이디 값을 초기값으로 초기화
            moveToInitialChatPage();
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    const deleteAllChatRooms = async () => {
      try {
        if (userSid) {
          await deleteAllChats(userSid);
          if (chatList.length > 0) {
            // 채팅방 삭제 API 요청 후 삭제 처리 된 후의 채팅방 UI 업데이트
            const chatList = await getChatList({ user_id: userSid });
            setChatList(chatList);
            // 지운 채팅방 아이디 값을 초기값으로 초기화
            onSanitizeChatRoomState();
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    useImperativeHandle(ref, () => ({
      chatListState: {
        chatList,
        setChatList,
      },
    }));

    return (
      <>
        <ChatSidebar
          ref={lastElementRef}
          chatRoomsCollection={{
            chatRooms: chatList,
            innerwaveGuideChatRooms: chatFaqs,
          }}
          currentChatRoomIdState={currentChatRoomIdState}
          onOpenFeedbackModal={handleAddFeedback}
          onDeleteChatRoom={{
            onDeleteSingleChatRoom: deleteSingleChatRoom,
            onDeleteAllChatRooms: deleteAllChatRooms,
          }}
        />
        <ChatbotFeedbackFormModal {...feedbackFormModelInfo} />
      </>
    );
  },
);

const StyledContainer = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  boxShadow: '0px 0px 16px #0000005a',
  height: '100%',
  zIndex: 1295,
}));

export default observer(AiChatbot);
