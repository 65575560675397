/**
 * 채팅 창의 기본 레이아웃 컴포넌트
 * - 헤더, 본문, 하단 영역 구조화
 * - 스크롤 버튼 기능
 * - 반응형 레이아웃 처리
 */

import { useEffect, useRef, useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Fade, IconButton, Stack, Typography } from '@mui/material';

import { isMobile } from '../../utils/common';

const Skeleton = ({
  children,
  resizableChatWindowWidth,
  bottomComponent,
  onClose,
}: {
  children: React.ReactNode;
  resizableChatWindowWidth: number;
  bottomComponent?: React.ReactNode;
  onClose?: () => void;
}) => {
  // 스크롤 버튼의 표시 여부를 관리하는 상태
  const [showScrollButton, setShowScrollButton] = useState(false);
  // 스크롤 가능한 컨텐츠 영역에 대한 참조
  const contentRef = useRef<HTMLDivElement>(null);
  // 이전 스크롤 위치를 저장하는 ref (스크롤 방향을 감지하기 위해 사용)
  const lastScrollTop = useRef(0);

  // 스크롤 이벤트 핸들러: 스크롤 위치와 방향에 따라 버튼 표시 여부 결정
  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;

      // 스크롤이 맨 아래에서 100px 이상 올라왔는지 확인
      // - scrollHeight: 전체 컨텐츠 높이
      // - scrollTop: 현재 스크롤된 위치
      // - clientHeight: 화면에 보이는 영역의 높이
      const isScrolledUp = scrollHeight - scrollTop - clientHeight > 100;

      // 현재 스크롤 방향 확인 (이전 위치와 현재 위치 비교)
      // - scrollTop > lastScrollTop: 아래로 스크롤 중
      // - scrollTop < lastScrollTop: 위로 스크롤 중
      const isScrollingDown = scrollTop > lastScrollTop.current;

      // 스크롤이 맨 아래에서 100px 이상 올라왔고,
      // 아래로 스크롤하는 중일 때만 버튼 표시
      setShowScrollButton(isScrolledUp && isScrollingDown);

      // 다음 스크롤 방향 감지를 위해 현재 스크롤 위치 저장
      lastScrollTop.current = scrollTop;
    }
  };

  // 스크롤을 맨 아래로 이동시키는 함수
  const scrollToBottom = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: contentRef.current.scrollHeight,
        behavior: 'smooth', // 부드러운 스크롤 효과 적용
      });
    }
  };

  // 스크롤 이벤트 리스너 등록 및 정리
  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      // 스크롤 이벤트 리스너 등록
      contentElement.addEventListener('scroll', handleScroll);
      // 컴포넌트 언마운트 시 이벤트 리스너 제거
      return () => contentElement.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <Stack
      direction="column"
      sx={{
        display: 'flex',
        width: isMobile() ? '100vw' : `${resizableChatWindowWidth}px`,
        height: '100%',
        flexDirection: 'column',
        background: 'white',
        overflow: 'hidden', // 스크롤을 내부 Box에서만 동작하도록 hidden으로 설정
        position: 'relative', // 내부 요소의 절대 위치 지정을 위한 기준점 설정
      }}
    >
      <Stack
        direction="row"
        sx={{ display: 'flex', boxShadow: '0 0 10px 0 #0001', height: '67px' }}
      >
        <Typography
          variant="h5"
          sx={{
            flex: 1,
            display: isMobile() ? 'none' : 'flex',
            justifyContent: 'center',
            alignItems: 'end',
            paddingY: '15px',
          }}
        >
          무엇이든 물어보세요!
        </Typography>
        <IconButton onClick={onClose}>
          <CloseRoundedIcon />
        </IconButton>
      </Stack>
      <Box
        ref={contentRef} // 스크롤 이벤트를 감지하고 제어하기 위한 ref
        sx={{
          minHeight: '279px',
          overflow: 'auto', // 이 Box 내부에서만 스크롤이 동작하도록 설정
          flex: '1',
          paddingTop: isMobile() ? '15px' : 0,
          position: 'relative', // 스크롤 버튼의 위치 지정을 위한 기준점
        }}
      >
        {children}
        {/* 스크롤 버튼 컴포넌트 */}
        <Fade in={showScrollButton}>
          {/* showScrollButton 상태에 따라 부드럽게 나타나고 사라짐 */}
          <IconButton
            onClick={scrollToBottom}
            sx={{
              position: 'sticky', // 스크롤 시 항상 같은 위치에 고정
              bottom: '8px', // 하단에서 8px 떨어진 위치
              left: '50%', // 가운데 정렬을 위한 왼쪽 50% 위치
              transform: 'translateX(-50%)', // 정확한 가운데 정렬을 위한 이동
              backgroundColor: '#cde3fa', // 버튼 배경색
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)', // 그림자 효과
              transition: 'all 0.3s ease-in-out', // 호버 효과 전환 시간 설정
              padding: '5px',
              '&:hover': {
                backgroundColor: '#e6f2ff', // 호버 시 배경색 변경
              },
            }}
          >
            <KeyboardArrowDownIcon /> {/* 아래 화살표 아이콘 */}
          </IconButton>
        </Fade>
      </Box>
      {bottomComponent}
    </Stack>
  );
};

export default Skeleton;
