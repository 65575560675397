import { IServerSearchParams, TServerFormData } from 'src/@types';
import { IAttachment, IServer } from 'src/@types/apiResponseTypes';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

const SUB_URL = '/v1/server';

/**
 * Server 목록 조회
 * @param params 검색 조건
 */
export const gets = async (params?: IServerSearchParams) => {
  try {
    const url = SUB_URL;
    const response = await instance.get<TApiResponseData<IServer[]>>(url, {
      params,
    });

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Server 단건 조회
 * @param serverSid Server 고유번호
 */
export const get = async (serverSid: number) => {
  try {
    const url = `${SUB_URL}/${serverSid}`;
    const response = await instance.get<TApiResponseData<IServer>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Server 등록
 * @param formData Server 등록 정보: TServerFormData
 */
export const create = async (formData: TServerFormData) => {
  try {
    const url = SUB_URL;

    const form = new FormData();

    // 파일 추가
    formData.files?.forEach((file) => {
      if (file instanceof File) {
        form.append('files', file);
      }
    });

    const excludes = ['files'];

    (Object.keys(formData) as Array<keyof TServerFormData>).forEach((key) => {
      // 값이 있는 경우에만 append
      if (!excludes.includes(key)) {
        form.append(key, formData[key] as string);
      }
    });

    const response = await instance.post<TApiResponseData<IServer>>(url, form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Server 정보 수정
 * @param serverSid Server 고유번호
 * @param formData Server 등록 정보: TServerFormData
 */
export const update = async (serverSid: number, formData: TServerFormData) => {
  try {
    const url = `${SUB_URL}/${serverSid}`;

    const form = new FormData();

    // 파일 추가
    formData.files?.forEach((file) => {
      if (file instanceof File) {
        form.append('files', file);
      }
    });

    const excludes = ['files'];

    (Object.keys(formData) as Array<keyof TServerFormData>).forEach((key) => {
      // 값이 있는 경우에만 append
      if (!excludes.includes(key)) {
        form.append(key, formData[key] as string);
      }
    });

    const response = await instance.put<TApiResponseData<IServer>>(url, form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Server 삭제
 * @param serverSid Server 고유번호
 */
export const remove = async (serverSid: number) => {
  try {
    const url = `${SUB_URL}/${serverSid}`;
    const response = await instance.delete<TApiResponseData<boolean>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      // 삭제 성공 시 true 반환
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 보고서 첨부파일 삭제
 * attachment: IAttachment
 */
export const deleteFile = async (attachment: IAttachment) => {
  try {
    const url = `${SUB_URL}/${attachment.serverSid}/file/${attachment.fileSid}`;
    const response = await instance.delete<TApiResponseData<any>>(url);

    if (response.data.resultCode === 'S') {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};
