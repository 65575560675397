import { IVmSearchParams, TVmFormData } from 'src/@types';
import { IVm } from 'src/@types/apiResponseTypes';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

const SUB_URL = '/v1/vm';

/**
 * Vm 목록 조회
 * @param params 검색 조건
 */
export const gets = async (params?: IVmSearchParams) => {
  try {
    const url = SUB_URL;
    const response = await instance.get<TApiResponseData<IVm[]>>(url, {
      params,
    });

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Vm 단건 조회
 * @param vmSid Vm 고유번호
 */
export const get = async (vmSid: number) => {
  try {
    const url = `${SUB_URL}/${vmSid}`;
    const response = await instance.get<TApiResponseData<IVm>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Vm 등록
 * @param formData Vm 등록 정보: TVmFormData
 */
export const create = async (formData: TVmFormData) => {
  try {
    const url = SUB_URL;

    const response = await instance.post<TApiResponseData<IVm>>(url, formData);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Vm 정보 수정
 * @param vmSid Vm 고유번호
 * @param formData Vm 등록 정보: TVmFormData
 */
export const update = async (vmSid: number, formData: TVmFormData) => {
  try {
    const url = `${SUB_URL}/${vmSid}`;
    const response = await instance.put<TApiResponseData<IVm>>(url, formData);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Vm 삭제
 * @param vmSid Vm 고유번호
 */
export const remove = async (vmSid: number) => {
  try {
    const url = `${SUB_URL}/${vmSid}`;
    const response = await instance.delete<TApiResponseData<boolean>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      // 삭제 성공 시 true 반환
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};
