import { commonApi } from 'src/api';
import { TUploadFile } from 'src/components/upload';

import { getMimeTypeExtension } from './getMimeTypeExtension';

type TDownloadFilePayload = {
  file?: TUploadFile;
  fileName?: string;
  apiUrl?: string;
};

/**
 * base64 문자열 유효성 검사
 */
const isBase64 = (str: string): boolean => {
  try {
    const regex = /^data:.*?;base64,/;
    const base64Data = regex.test(str) ? str : `data:application/octet-stream;base64,${str}`;
    return btoa(atob(base64Data.split(',')[1])) === base64Data.split(',')[1];
  } catch {
    return false;
  }
};

/**
 * download 처리 함수
 */
const download = (data: Blob | File, fileName?: string) => {
  // Blob 데이터를 이용하여 클라이언트에서 파일 생성
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName || 'download_file'); // 다운로드할 파일명 지정
  document.body.appendChild(link);
  link.click();

  // 정리
  window.URL.revokeObjectURL(url); // 사용이 끝난 후 URL 해제
  link.remove(); // 생성한 링크 요소 제거
};

/**
 * 파일 타입별 api주소 반환하는 함수
 */
export const FILE_DOWNLOAD_API_URL = {
  report: (reportSid?: number, fileSid?: number) => {
    if (!reportSid || !fileSid) {
      return '';
    }
    return `/v1/report/${reportSid}/file/${fileSid}/download`;
  },
  reportZip: (reportSid?: number) => {
    if (!reportSid) {
      return '';
    }
    return `/v1/report/${reportSid}/file/download`;
  },
  equipment: (equipmentSid?: number, fileSid?: number) => {
    if (!equipmentSid || !fileSid) {
      return '';
    }
    return `/v1/equipment/${equipmentSid}/file/${fileSid}/download`;
  },
  project: () => {
    return `/v1/project/excel`;
  },
  server: (serverSid?: number, fileSid?: number) => {
    if (!serverSid || !fileSid) {
      return '';
    }
    return `/v1/server/${serverSid}/file/${fileSid}/download`;
  },
} as const;

/**
 * 파일 다운로드
 * - 파일 타입에 따라 파일을 받아 다운로드를 처리한다.
 * @param file 파일 데이터 TUploadFile (optional)
 * @param fileName 파일 이름 (optional)
 * @param apiUrl 파일 다운로드 api url (optional)
 */
export const downloadFile = async ({ file, fileName, apiUrl }: TDownloadFilePayload) => {
  try {
    // file type이 string인 경우 다운로드 할 수 없으므로 base64인지 확인하여 download 한다.
    if (typeof file === 'string') {
      if (!isBase64(file)) {
        throw new Error('Invalid base64 string');
      }

      // MIME 타입 추출
      const mimeMatch = file.match(/^data:(.*?);base64,/);
      const mimeType = mimeMatch ? mimeMatch[1] : 'application/octet-stream';

      const base64Response = await fetch(
        `data:${mimeType};base64,${file.replace(/^data:.*?;base64,/, '')}`,
      );
      const blob = await base64Response.blob();

      // 파일명이 제공되지 않은 경우에만 확장자 추출
      if (!fileName) {
        const extension = getMimeTypeExtension(mimeType);
        fileName = `download_file.${extension}`;
      }

      download(blob, fileName);
      return;
    }

    // file type이 File일 경우 바로 다운로드 한다.
    if (file instanceof File) {
      download(file, fileName || file.name);
      return;
    }

    // api url이 있는경우 해당 api url로 파일 다운로드
    if (apiUrl) {
      const blobPart = await commonApi.getFileBlob(apiUrl);
      if (!blobPart) {
        throw new Error('failed to get file blob');
      }
      download(new Blob([blobPart]), fileName || file?.fileNm);
      return;
    }

    // 파일 타입이 없는 경우 에러 발생
    throw new Error('file type is not supported');
  } catch (error) {
    console.error('failed to download file', error);
  }
};
