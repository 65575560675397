import { useStores } from 'src/models';
import { COMMON_CODE_NAME, DOCU_TYPE_CODE } from 'src/static/constants';
import { convertCommonCodeToOptions } from 'src/utils/convertCodeToOtions';

// default 업무보고 문서유형 코드
export const DEFAULT_WORK_DOCU_CODE = DOCU_TYPE_CODE.WEEKLY;

type ReturnType = {
  docuTypeOptions: { label: string; value: number }[];
};

/**
 * 문서유형 관련 커스텀 훅
 */
function useDocuType(): ReturnType {
  const { commonCodeStore } = useStores();

  // stored docuTypeCd
  // const [_, setStoredDocuTypeCode] = useLocalStorage(LOCAL_STORAGE_KEY.DOCU_TYPE_CODE, undefined);

  // 문서유형 코드
  const DOCU_TYPE_CD_LIST = commonCodeStore.getCCodeByName(COMMON_CODE_NAME.DOCU_TYPE_CD);

  // 회의록을 제외한 문서유형 옵션
  const docuTypeOptions = convertCommonCodeToOptions(
    DOCU_TYPE_CD_LIST.filter(({ code }) => code !== DOCU_TYPE_CODE.MEETING),
  );

  return {
    docuTypeOptions,
  };
}

export default useDocuType;
