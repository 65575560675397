/**
 * 무한 스크롤 기능 커스텀 훅
 * - 스크롤 이벤트 처리
 * - 데이터 페이징 처리
 * - 옵저버 패턴 활용
 */
import { useCallback, useEffect, useRef, useState } from 'react';
import { uniqBy } from 'lodash';

import { ChatListRequest } from '../utils/types';

interface InfiniteScrollOptions<TResponse> {
  /** 초기 페이지 번호 */
  initialPage?: number;
  /** 스크롤 감지 임계값 */
  threshold?: number;
  /** 페이지 파라미터 생성 함수 */
  getPageParam: (page: number) => ChatListRequest | null;
  /** 데이터 로드 성공 시 콜백 함수 */
  onSuccess?: (data: TResponse[]) => void;
  /** 의존성 배열 */
  dependencies?: any[];
}

const useInfiniteScroll = <TResponse>(
  fetchData: (chatListRequest: ChatListRequest) => Promise<TResponse[]>,
  options: InfiniteScrollOptions<TResponse>,
) => {
  const { initialPage = 1, threshold = 0, getPageParam, onSuccess } = options;

  const [page, setPage] = useState<number>(initialPage);
  const [data, setData] = useState<TResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const params = getPageParam(page);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useRef<HTMLLIElement | null>(null);
  const currentThreshold = useRef<number>(threshold);

  const loadMore = useCallback(async () => {
    if (loading || !hasMore) return;
    if (params === null) return;
    setLoading(true);
    try {
      const newItems = await fetchData(params);
      if (newItems.length === 0) {
        setHasMore(false);
      } else {
        setData((prevData) => {
          const updatedData = uniqBy([...prevData, ...newItems], 'chat_id');
          if (onSuccess) {
            onSuccess(updatedData);
          }
          return updatedData;
        });
        setPage((prevPage) => prevPage + 1);
      }
    } catch (err) {
      setError(err instanceof Error ? err : new Error('An error occurred'));
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore, getPageParam, page, fetchData, onSuccess, params]);

  const lastElementCallback = useCallback(
    (node: HTMLLIElement | null) => {
      if (loading) return;

      // 새로운 Observer를 생성하기 전에 기존 Observer를 정리
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0] && entries[0].isIntersecting && hasMore) {
            loadMore();
          }
        },
        {
          threshold: [0, 0.25, 0.5, 0.75, 1],
        },
      );

      // HTML node가 회면에 등장하는지 감시해줌
      if (node) observer.current.observe(node);
      lastElementRef.current = node;
    },
    [loading, hasMore, loadMore, threshold],
  );

  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (threshold !== currentThreshold.current) {
      currentThreshold.current = threshold;
      if (lastElementRef.current) {
        lastElementCallback(lastElementRef.current);
      }
    }
  }, [threshold, lastElementCallback]);

  useEffect(() => {
    // 초기 데이터 로드
    setPage(initialPage);
    // setData([]);
    // setHasMore(true);
    loadMore();
  }, [initialPage, threshold, params?.user_id]);

  return { page, data, loading, error, hasMore, lastElementRef: lastElementCallback };
};

export default useInfiniteScroll;
