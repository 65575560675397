import { IProjectSearchParams, TProjectFormData } from 'src/@types';
import { IProject, IProjectDashboard } from 'src/@types/apiResponseTypes';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

const SUB_URL = '/v1/project';

/**
 * 프로젝트 목록 조회
 * @param params 검색 조건
 */
export const getProjects = async (params?: IProjectSearchParams) => {
  try {
    const url = SUB_URL;
    const response = await instance.get<TApiResponseData<IProject[]>>(url, {
      params,
    });

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 프로젝트 단건 조회
 * @param projectSid 프로젝트 고유번호
 */
export const findProject = async (projectSid: number) => {
  try {
    const url = `${SUB_URL}/${projectSid}`;
    const response = await instance.get<TApiResponseData<IProject>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 프로젝트 등록
 * @param projectInfo 프로젝트 등록 정보: TProjectFormData
 */
export const createProject = async (projectInfo: TProjectFormData) => {
  try {
    const url = SUB_URL;
    const response = await instance.post<TApiResponseData<IProject>>(url, projectInfo);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 프로젝트 정보 수정
 * @param projectSid 프로젝트 고유번호
 * @param projectInfo 프로젝트 등록 정보: TProjectFormData
 */
export const updateProject = async (projectSid: number, projectInfo: TProjectFormData) => {
  try {
    const url = `${SUB_URL}/${projectSid}`;
    const payload = projectInfo;
    const response = await instance.put<TApiResponseData<IProject>>(url, payload);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 프로젝트 삭제
 * @param projectSid 프로젝트 고유번호
 */
export const deleteProject = async (projectSid: number) => {
  try {
    const url = `${SUB_URL}/${projectSid}`;
    const response = await instance.delete<TApiResponseData<boolean>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      // 삭제 성공 시 true 반환
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 프로젝트 현황 조회
 */
export const getProjectDashboard = async () => {
  try {
    const url = `${SUB_URL}/dashboard`;
    const response = await instance.get<TApiResponseData<IProjectDashboard>>(url);

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      // 삭제 성공 시 true 반환
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 프로젝트 관리 아이디 조회
 */
export const getProjectManageId = async (projectMngTypeCd: number) => {
  try {
    const url = `${SUB_URL}/manage/id`;
    const response = await instance.get<TApiResponseData<string>>(url, {
      params: {
        projectMngTypeCd,
      },
    });

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      // 삭제 성공 시 true 반환
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};
