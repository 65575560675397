import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, DialogContent, Drawer, Link, Stack } from '@mui/material';
import { DialogAnimate } from 'src/components/animate';
import DialogTitleWithCloseButton from 'src/components/dialog/DialogTitleWithCloseButton';
import useNavData from 'src/hooks/useNavData';
import SearchInput from 'src/screens/common/ai-chatbot/components/search-input';
import ChangeLog from 'src/screens/common/ChangeLog';
// config
import { NAV } from 'src/static/constants';

// components
import { NavSectionVertical } from '../../../components/nav-section';
import Scrollbar from '../../../components/scrollbar';
// hooks
import useResponsive from '../../../hooks/useResponsive';

import NavAccount from './NavAccount';
import NavToggleButton from './NavToggleButton';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
  onOpenMyInfoModal?: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav, onOpenMyInfoModal }: Props) {
  const { pathname } = useLocation();
  const navData = useNavData();

  const isDesktop = useResponsive('up', 'lg');

  const [openChangeLog, setOpenChangeLog] = useState(false);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <NavAccount onOpenMyInfoModal={onOpenMyInfoModal} />
      </Stack>
      {isDesktop && <SearchInput />}
      <NavSectionVertical data={navData} />
      {/* <OpenAiForm /> */}
      <Box sx={{ flexGrow: 1, minHeight: 100 }} />
      <Link sx={{ alignSelf: 'center', pb: 2 }} onClick={() => setOpenChangeLog((prev) => !prev)}>
        Changelog
      </Link>
    </Scrollbar>
  );
  const closeChangeLog = () => setOpenChangeLog(false);

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
      {isDesktop && <NavToggleButton />}
      <DialogAnimate
        fullWidth
        maxWidth="md"
        open={openChangeLog}
        onClose={closeChangeLog}
        scroll="paper"
        sx={{ height: '100%' }}
      >
        <DialogTitleWithCloseButton
          title={`Works Changelog`}
          onClose={closeChangeLog}
        ></DialogTitleWithCloseButton>
        <DialogContent dividers>
          <ChangeLog />
        </DialogContent>
      </DialogAnimate>
    </Box>
  );
}
