import React, { useEffect, useState } from 'react';
import Lightbox from 'src/components/lightbox/Lightbox';
import { Slide } from 'yet-another-react-lightbox/*';

import { MenuProps } from '../../../components/menus/types';

/**
 * 이미지 블록 클릭 시 노출되는 라이트박스 컴포넌트
 */
export const ImageBlockLightbox = ({ editor }: MenuProps): JSX.Element => {
  const [slides, setSlides] = useState<Slide[]>([]);
  const [lightboxProps, setLightboxProps] = useState({
    open: false,
    index: 0,
  });

  // update slides
  useEffect(() => {
    editor.getJSON().content?.forEach((item: any) => {
      if (item.type === 'imageBlock') {
        setSlides((prev) => [...prev, { src: item.attrs.src }]);
      }
    });
  }, [editor]);

  // event listener
  useEffect(() => {
    const editorElement = editor.view.dom;
    const openLightbox = (event: any) => {
      // lightbox open & set index
      setLightboxProps({
        open: true,
        index:
          slides.findIndex((slide) => slide && 'src' in slide && slide.src === event.detail) || 0,
      });
    };

    editorElement.addEventListener('openLightbox', openLightbox);

    return () => {
      editorElement.removeEventListener('openLightbox', openLightbox);
    };
  }, [editor.view.dom, slides]);

  return (
    <Lightbox
      index={lightboxProps.index}
      open={lightboxProps.open}
      close={() => setLightboxProps({ open: false, index: 0 })}
      slides={slides}
    />
  );
};

export default ImageBlockLightbox;
