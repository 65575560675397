import { useRef, useState } from 'react';

import {
  AI_RESPONSE_DEFAULT_VALUE,
  AIResponse,
  ChatList,
  ChatListRequest,
  ConversationType,
  TSetState,
} from '../utils/types';

import useChatResponse from './useChatResponse';

/**
 * 챗봇 상태 관리 인터페이스
 * - 채팅 상태 관리
 * - 채팅 액션 관리
 * - 사이드바 참조 관리
 */
export interface ChatState {
  /** 현재 대화 내용 */
  conversations: ConversationType[] | undefined;
  /** 채팅창 열림 여부 */
  isChatWindowOpened: boolean;
  /** 초기 페이지 여부 -> 챗봇의 New Chat 버튼을 누르면 보이는 챗봇 화면이다. */
  isInitialChatPage: boolean;
  /** 현재 채팅방 ID */
  currentChatRoomId: string;
  /** AI 응답 데이터 */
  aiResponse: AIResponse;
  /** AI 응답 상태 */
  aiResponseStatus: {
    isAITyping: boolean;
    error: string | null;
  };
  /** 스트리밍 진행 중 여부 */
  isStreaming: boolean;
  /** 사이드바 참조 */
  sidebarRef: React.RefObject<SidebarRef>;
}

/**
 * 챗봇 액션 인터페이스
 * - 채팅 액션 관리
 */
export interface ChatActions {
  /** AI 응답 설정 함수 */
  setAiResponse: TSetState<AIResponse>;
  /** AI 응답 상태 설정 함수 */
  setAiResponseStatus: TSetState<{
    isAITyping: boolean;
    error: string | null;
  }>;
  /** 현재 채팅방 ID 설정 함수 */
  setCurrentChatRoomId: TSetState<string>;
  /** 채팅 목록 설정 함수 */
  setConversations: TSetState<ConversationType[] | undefined>;
  /** 스트리밍 진행 중 여부 설정 함수 */
  setIsStreaming: TSetState<boolean>;
  /** 채팅창 열림 여부 설정 함수 */
  setIsChatWindowOpened: TSetState<boolean>;
  /** 초기 페이지 여부 설정 함수 */
  setIsInitialChatPage: TSetState<boolean>;
  /** 채팅 히스토리 조회 함수 */
  findChatHistory: (params: { chat_id: string }) => Promise<void>;
  /** 채팅 목록 조회 함수 */
  getChatList: (params: ChatListRequest) => Promise<ChatList[]>;
  /** 채팅방 상태 정리 함수 */
  sanitizeChatRoomState: () => void;
  /** 초기 페이지로 이동 함수 */
  moveToInitialChatPage: () => void;
}

interface SidebarRef {
  chatListState: {
    chatList: ChatList[];
    setChatList: TSetState<ChatList[]>;
  };
}

export const useChatState = (
  initialAiResponse: AIResponse = AI_RESPONSE_DEFAULT_VALUE,
): ChatState & ChatActions => {
  const { history, conv, getChatList } = useChatResponse();

  const [isChatWindowOpened, setIsChatWindowOpened] = useState(false);
  const [currentChatRoomId, setCurrentChatRoomId] = useState<string>('');
  const [aiResponse, setAiResponse] = useState<AIResponse>(initialAiResponse);
  const [aiResponseStatus, setAiResponseStatus] = useState<{
    isAITyping: boolean;
    error: string | null;
  }>({ isAITyping: false, error: null });
  const [isStreaming, setIsStreaming] = useState(false);
  const [isInitialChatPage, setIsInitialChatPage] = useState(true);

  const sidebarRef = useRef<SidebarRef>(null);

  const { conversations, setConversations } = conv;
  const { findChatHistory } = history;

  const sanitizeChatRoomState = () => {
    setCurrentChatRoomId('');
    setConversations([]);
  };

  const moveToInitialChatPage = () => {
    sanitizeChatRoomState();
    setIsInitialChatPage(true);
  };

  return {
    // State
    aiResponse,
    aiResponseStatus,
    conversations,
    currentChatRoomId,
    sidebarRef,
    isStreaming,
    isChatWindowOpened,
    isInitialChatPage,

    // Actions
    setAiResponse,
    setAiResponseStatus,
    setCurrentChatRoomId,
    setConversations,
    setIsStreaming,
    setIsChatWindowOpened,
    setIsInitialChatPage,
    findChatHistory,
    getChatList,
    sanitizeChatRoomState,
    moveToInitialChatPage,
  };
};
