import { useEffect, useState } from 'react';

const NUMBER_OF_DOTS = 5;
const SPEED_OF_DOTS = 200; // millisecond

/**
 * 로딩 애니메이션 컴포넌트
 * - 점(...)이 순차적으로 나타나는 애니메이션 효과
 * - AI 응답 대기 시 사용
 */
const LoadingDots = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev.length >= NUMBER_OF_DOTS ? '' : prev + '.'));
    }, SPEED_OF_DOTS);

    return () => clearInterval(interval);
  }, []);

  return <span className="inline-block min-w-[24px]">{dots}</span>;
};

export default LoadingDots;
