import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Typography } from '@mui/material';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';

import { useMessageInput } from '../../hooks/useMessageInput';

// 메시지 입력 영역 컴포넌트의 props 타입 정의
interface MessageInputAreaProps {
  /** AI 처리 중 여부 */
  isAiProcessing: boolean;
  /** AI에 메시지 전송하는 함수 */
  onSendMessageToAI: (response: string) => void;
}

function MessageInputArea(props: MessageInputAreaProps) {
  // useMessageInput 훅을 사용하여 메시지 입력 관련 로직과 상태 관리
  const { methods, inputMessage, MAX_CHARS, setIsComposing, onHandleSubmit, handleEnterKeyPress } =
    useMessageInput(props);

  return (
    // 메시지 입력 영역의 최상위 컨테이너
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 12px 16px',
        gap: 8,
      }}
    >
      {/* 폼 제공자 컴포넌트로 폼 상태 관리 */}
      <FormProvider
        methods={methods}
        onSubmit={methods.handleSubmit(onHandleSubmit)}
        sx={{
          border: '2px solid #0F85FB',
          borderRadius: '8px',
          padding: '20px',
        }}
      >
        {/* 메시지 입력을 위한 텍스트 필드 */}
        <RHFTextField
          multiline // Shift + Enter로 줄바꿈 가능
          name="response"
          placeholder="궁금한 사항을 구체적으로 작성해주세요."
          variant="standard"
          inputProps={{ maxLength: MAX_CHARS }}
          inputRef={(textFieldInput) => textFieldInput && textFieldInput.focus()} // <TextField /> 가 렌더링 되었을 때 자동으로 텍스트 필드에 포커싱하기
          sx={{
            // 입력 필드의 스타일링
            '& .MuiInputBase-root': {
              border: 'none',
              borderRadius: '8px',
              height: '180px', // 높이를 180px로 설정
              overflow: 'auto',
            },
            '& .MuiInputBase-input': {
              height: '100% !important', // 입력 영역이 전체 높이를 차지하도록 설정
              overflowY: 'auto !important', // 세로 스크롤 활성화
            },
            // --------------------------------------------------------------------------
            /** TextField의 기본적으로 있는 검은색 밑줄 제거 */
            '& .MuiInput-underline:before': {
              borderBottom: 'none',
            },
            '& .MuiInput-underline:after': {
              borderBottom: 'none',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottom: 'none',
            },
          }}
          // 키 입력 및 IME 조합 이벤트 핸들러
          onKeyDown={(event) => handleEnterKeyPress(event, methods.handleSubmit)}
          onCompositionStart={() => setIsComposing(true)}
          onCompositionEnd={() => setIsComposing(false)}
        />
        {/* 문자 수 카운터와 전송 버튼 컨테이너 */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          {/* 입력된 문자 수 표시 */}
          <Typography variant="caption" sx={{ mt: 1, display: 'block' }}>
            {inputMessage.length} / {MAX_CHARS}
          </Typography>
          {/* 메시지 전송 버튼 */}
          <Button
            variant="outlined"
            sx={{ borderRadius: '100px' }}
            startIcon={<SendIcon sx={{ fontSize: 13 }} />}
            // 입력이 없거나 최대 문자수를 초과하면 비활성화
            disabled={inputMessage.length === 0 || inputMessage.length > MAX_CHARS}
            onClick={() => {
              onHandleSubmit({ response: methods.getValues().response });
            }}
          >
            보내기
          </Button>
        </Box>
      </FormProvider>
    </Box>
  );
}

export default MessageInputArea;
