import { RefObject, useCallback } from 'react';
import { maxBy, uniqBy } from 'lodash';

import {
  AIResponse,
  ChatList,
  ChatListRequest,
  ConversationType,
  TSetState,
  UserType,
} from '../utils/types';

interface SidebarRef {
  chatListState: {
    // 채팅 목록
    chatList: ChatList[];
    // 채팅 목록 설정 함수
    setChatList: TSetState<ChatList[]>;
  };
}

interface UseSendChatMessageProps {
  /** 현재 채팅방 ID */
  currentChatRoomId: string;
  /** 현재 채팅방 ID 설정 함수 */
  setCurrentChatRoomId: TSetState<string>;
  /** 대화 내용 설정 함수 */
  setConversations: TSetState<ConversationType[] | undefined>;
  /** AI 응답 설정 함수 */
  setAiResponse: TSetState<AIResponse>;
  /** AI 응답 상태 설정 함수 */
  setAiResponseStatus: TSetState<{
    isAITyping: boolean;
    error: string | null;
  }>;
  /** 스트리밍 상태 설정 함수 */
  setIsStreaming: TSetState<boolean>;
  /** 채팅 목록 조회 함수 */
  getChatList: (params: ChatListRequest) => Promise<ChatList[]>;
  /** 초기 페이지 여부 설정 함수 */
  setIsInitialChatPage: TSetState<boolean>;
  /** 사이드바 참조 */
  sidebarRef: React.RefObject<SidebarRef>;
  /** AI 응답 기본값 */
  AI_RESPONSE_DEFAULT_VALUE: AIResponse;
}

// AI 응답 요청 함수
const fetchChatResponse = async (userPayload: UserType) => {
  const url = `/v1/chatbot/chats/response`;
  const response = await fetch(process.env.REACT_APP_CHATBOT_URL + url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userPayload),
  });

  return response;
};

// 스트림 처크 처리 함수
const processStreamChunk = async (
  chunk: Uint8Array,
  decoder: TextDecoder,
  onChunkParsed: (parsedChunk: AIResponse) => void,
) => {
  const decodedChunk = decoder.decode(chunk, { stream: true });
  const lines = decodedChunk.split('\n');

  for (const line of lines) {
    if (line.startsWith('data: ')) {
      try {
        const parsedChunk = JSON.parse(line.slice(5)) as AIResponse;
        onChunkParsed(parsedChunk);
      } catch (error) {
        console.error('JSON Parsing error - ', error);
      }
    }
  }
};

// 채팅 상태 업데이트 함수
const updateChatState = async (
  parsedChunk: AIResponse,
  {
    user_sid,
    sidebarRef,
    getChatList,
    setCurrentChatRoomId,
    setConversations,
    setAiResponse,
    AI_RESPONSE_DEFAULT_VALUE,
  }: {
    user_sid: number;
    sidebarRef: RefObject<SidebarRef>;
    getChatList: (params: { user_id: number }) => Promise<ChatList[]>;
    setCurrentChatRoomId: (id: string) => void;
    setConversations: TSetState<ConversationType[] | undefined>;
    setAiResponse: TSetState<AIResponse>;
    AI_RESPONSE_DEFAULT_VALUE: AIResponse;
  },
) => {
  const { chat_id, chat_title, response, complete, user_id, is_faq, ord, reg_dt } = parsedChunk;

  if (sidebarRef.current) {
    const newChatList = await getChatList({ user_id: user_sid });
    const newChat = maxBy(newChatList, (chat) => new Date(chat.reg_dt));
    if (newChat) {
      sidebarRef.current.chatListState.setChatList((prevChatList) => {
        return uniqBy([newChat, ...prevChatList], 'chat_id');
      });
    }
  }

  setCurrentChatRoomId(chat_id);
  setConversations((prev) =>
    prev?.map((conv, index: number) =>
      index === prev.length - 1
        ? {
            ...conv,
            aiResponse: {
              user_id,
              chat_title,
              response,
              chat_id,
              ord,
              reg_dt,
              complete,
              is_faq,
            },
          }
        : conv,
    ),
  );
  setAiResponse(AI_RESPONSE_DEFAULT_VALUE);
};

export function useSendChatMessage({
  currentChatRoomId,
  sidebarRef,
  setCurrentChatRoomId,
  setConversations,
  setAiResponse,
  setAiResponseStatus,
  setIsStreaming,
  setIsInitialChatPage,
  getChatList,
  AI_RESPONSE_DEFAULT_VALUE,
}: UseSendChatMessageProps) {
  const sendMessageToAI = useCallback(
    async (user_input: string, user_sid?: number) => {
      if (user_sid) {
        const userPayload =
          currentChatRoomId.length === 0
            ? { user_id: user_sid, user_input, reg_dt: '' }
            : { user_id: user_sid, user_input, reg_dt: '', chat_id: currentChatRoomId };

        const newConversation: ConversationType = {
          userMessage: userPayload,
          aiResponse: {
            response: '',
            chat_id: '',
            user_id: user_sid,
            ord: 0,
            reg_dt: '',
            is_faq: false,
          },
        };

        setIsInitialChatPage(false);
        setConversations((prev) => {
          if (prev && prev.length > 0) {
            return [...prev, newConversation];
          } else {
            return [newConversation];
          }
        });

        try {
          setAiResponseStatus((prev) => ({ ...prev, isAITyping: true }));

          const response = await fetchChatResponse(userPayload);

          const reader = response.body?.getReader();
          const decoder = new TextDecoder();

          if (reader) {
            let done = false;
            while (!done) {
              const { value, done: doneReading } = await reader.read();
              done = doneReading;
              setAiResponseStatus((prev) => ({ ...prev, isAITyping: false }));
              setIsStreaming(true);

              if (value) {
                await processStreamChunk(value, decoder, async (parsedChunk) => {
                  setAiResponse(parsedChunk);

                  if (parsedChunk.complete) {
                    await updateChatState(parsedChunk, {
                      user_sid,
                      sidebarRef,
                      getChatList,
                      setCurrentChatRoomId,
                      setConversations,
                      setAiResponse,
                      AI_RESPONSE_DEFAULT_VALUE,
                    });
                  }
                });
              }
            }
          }
        } catch (error) {
          console.error('Error fetching stream data: ', error);
        } finally {
          setAiResponseStatus((prev) => ({ ...prev, isAITyping: false }));
          setIsStreaming(false);
        }
      }
    },
    [
      currentChatRoomId,
      setIsInitialChatPage,
      setConversations,
      setAiResponseStatus,
      setIsStreaming,
      setAiResponse,
      sidebarRef,
      setCurrentChatRoomId,
      AI_RESPONSE_DEFAULT_VALUE,
      getChatList,
    ],
  );

  return { sendMessageToAI };
}

export default useSendChatMessage;
