import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { departmentApi } from 'src/api';

import { DepartmentModel } from '../department/Department';
import { withRootStore } from '../extensions/withRootStore';
import { withSetPropAction } from '../extensions/withSetPropAction';

/**
 * # DepartmentStore
 * - 부서 상태 관리 모델
 */
export const DepartmentStoreModel = types
  .model('DepartmentStore')
  .props({
    departments: types.optional(types.array(DepartmentModel), []),
  })
  .extend(withRootStore)
  .actions(withSetPropAction)
  .actions((self) => ({
    getDepartments: async (deptNm?: string) => {
      const result = await departmentApi.getDepartments(deptNm);
      if (result) {
        self.setProp('departments', result.data);
        return;
      }
    },
  }))
  .actions((self) => ({
    afterCreate() {
      // 새로고침 시 부서 목록 조회
      self.getDepartments();
    },
  }));

type TDepartmentStoreType = Instance<typeof DepartmentStoreModel>;
export interface IDepartmentStore extends TDepartmentStoreType {}
type TDepartmentStoreSnapshotType = SnapshotOut<typeof DepartmentStoreModel>;
export interface IDepartmentStoreSnapshot extends TDepartmentStoreSnapshotType {}
