import { useCallback, useEffect, useRef, useState } from 'react';
import { BoxProps } from '@mui/material';

// 리사이즈 가능한 박스의 props 타입 정의
interface ResizableBoxProps extends BoxProps {
  minWidth: number;
  maxWidth: number;
}

function useResizeContainer({ minWidth, maxWidth }: ResizableBoxProps) {
  // 채팅창의 현재 너비 상태
  const [resizableChatWindowWidth, setResizableWidth] = useState(minWidth);
  // 현재 리사이징 중인지 여부
  const [isResizing, setIsResizing] = useState(false);
  // 컨테이너 요소에 대한 ref
  const containerRef = useRef<HTMLDivElement>(null);
  const startXRef = useRef(0); // 드래그 시작 지점
  const startWidthRef = useRef(0); // 드래그 시작 시점의 사이드바 너비

  // 마우스 클릭 이벤트 - 너비 조절
  const handleResizeWidth = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      // 마우스 드래그 시작 지점 저장하여 이후의 마우스 이동을 이 시작점과 비교하여 얼마나 이동했는지 계산할 수 있게 함
      startXRef.current = e.clientX;
      // 드래그 시작 시점의 너비 저장하여 이 초기 너비를 기준으로 드래그 중에 너비 변화를 계산할 수 있게 함.
      startWidthRef.current = resizableChatWindowWidth;
      setIsResizing(true);
    },
    [resizableChatWindowWidth],
  );

  // 마우스 업 이벤트 핸들러 - 리사이징 종료
  const handleMouseUp = useCallback(() => {
    setIsResizing(false);
  }, []);

  // 마우스 이동 이벤트 핸들러 - 너비 계산 및 적용
  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isResizing && containerRef.current) {
        /**
         * startXRef.current: 드래그를 시작한 초기 위치의 X 좌표
         * e.clientX: 현재 마우스 포인터의 X 좌표
         * 양수 dx: 마우스가 왼쪽으로 이동
         * 음수 dx: 마우스가 오른쪽으로 이동
         */
        const dx = startXRef.current - e.clientX;
        /**
         * 초기 너비가 200px이고, 마우스를 오른쪽으로 30px 이동했으면
         * startWidthRef.current = 200
         * dx = -30 (오른쪽으로 이동했으므로 음수)
         * newChatWindowWidth = 200 + (-30) = 170px

         * 반대로, 마우스를 왼쪽으로 50px 이동했다면
         * startWidthRef.current = 200
         * dx = 50 (왼쪽으로 이동했으므로 양수)
         * newChatWindowWidth = 200 + 50 = 250
         */
        const newChatWindowWidth = startWidthRef.current + dx;
        // 최소/최대 너비 제한 내에서 새로운 너비 설정
        /**
         * // minWidth: 200, maxWidth: 600인 경우
            Math.min(Math.max(150, 200), 600)  // → 200 (최소값으로 제한)
            Math.min(Math.max(400, 200), 600)  // → 400 (범위 내 값 그대로 유지)
            Math.min(Math.max(800, 200), 600)  // → 600 (최대값으로 제한)
         */
        const minWidthInRange = Math.min(Math.max(newChatWindowWidth, minWidth), maxWidth);
        setResizableWidth(minWidthInRange);
      }
    },
    [isResizing, minWidth, maxWidth],
  );

  // 리사이징 중일 때 마우스 이벤트 리스너 등록/해제
  useEffect(() => {
    // 마우스 왼쪽 누르고 커서를 이동할 때 이벤트 등록
    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    // 이벤트 해제
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing, handleMouseMove, handleMouseUp]);

  return { containerRef, resizableChatWindowWidth, handleResizeWidth };
}

export default useResizeContainer;
