import { ICodeModelSnapshot, IDynamicCodeModelSnapshot } from 'src/models';

/**
 * 공통 코드 목록을 옵션 목록으로 변환하는 함수
 * @param codes 공통 코드 목록
 * @returns 옵션 목록
 */
export const convertCommonCodeToOptions = (codes: ICodeModelSnapshot[]) => {
  return codes.map((code) => ({
    label: code.value,
    value: code.code,
  }));
};

/**
 * 동적 코드 목록을 옵션 목록으로 변환하는 함수
 * @param codes 동적 코드 목록
 * @returns 옵션 목록
 */
export const convertDynamicCodeToOptions = (codes: IDynamicCodeModelSnapshot[]) => {
  return codes.map((code) => ({
    label: code.cdNm,
    value: code.cdId,
  }));
};
