import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, IconButton, Stack, styled } from '@mui/material';

import { isMobile } from '../../utils/common';
import CyclingTypingAnimation from '../Animations/CyclingTypingAnimation';

interface InitialChatWindowProps {
  /** 크기 조절이 가능한 채팅창 너비 */
  resizableChatWindowWidth: number;
  /** 메시지 입력 컴포넌트 */
  MessageInput?: React.ReactElement;
  /** FAQ 태그 추천 컴포넌트 */
  FaqTagRecommender?: React.ReactElement;
  /** 채팅창 닫기 함수 */
  onClose: () => void;
}

function InitialChatWindow({
  resizableChatWindowWidth,
  MessageInput,
  FaqTagRecommender,
  onClose,
}: InitialChatWindowProps) {
  const texts = [
    '무엇이든 물어보세요!',
    '"전자결제 하는 방법 알려줘"',
    '"프로젝트 품의 및 결의 방법 알려줄래"',
    '"예비군 및 민방위 훈련 연차 등록 가이드"',
  ];
  return (
    <Stack
      direction="column"
      sx={{
        display: 'flex',
        width: isMobile() ? '100vw' : `${resizableChatWindowWidth}px`,
        height: '100%',
        flexDirection: 'column',
        background: 'white',
        overflow: 'auto',
      }}
    >
      <Stack
        direction="row"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          backgroundColor: 'white',
          paddingY: '10px',
        }}
      >
        <IconButton onClick={onClose}>
          <CloseRoundedIcon />
        </IconButton>
      </Stack>
      <Container>
        <Box>
          <IntroPharseBox>
            <CyclingTypingAnimation
              texts={texts}
              typingSpeed={100}
              deletingSpeed={50}
              delayBetweenTexts={3000}
              style={{ fontSize: '1.2rem' }}
            />
            {/* <TypingAnimation
              text="사내 생활, 모든 궁금증을 해결해 드립니다!"
              typingSpeed={50}
              style={{ fontSize: '1rem' }}
            /> */}
          </IntroPharseBox>
          <Box>{MessageInput}</Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>{FaqTagRecommender}</Box>
          {/* <GuidelinesBanner>
            <Typography variant="caption">
              AI 챗봇이 질문에 대하여 올바르지 않은 답변을 할 수 있어요.
            </Typography>
            <Typography variant="caption">
              사내 챗봇 개선을 위해 많은 피드백 부탁드립니다.
            </Typography>
          </GuidelinesBanner> */}
        </Box>
      </Container>
    </Stack>
  );
}

const Container = styled(Box)(() => ({
  backgroundColor: 'white',
  width: '100%',
  height: '65vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const IntroPharseBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  marginBottom: '60px',
}));

const GuidelinesBanner = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  color: '#898b8e',
}));

export default InitialChatWindow;
