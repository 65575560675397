// form
import { ChangeEvent, FocusEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  min?: number;
  max?: number;
};

export default function RHFNumberInput({ name, min, max, helperText, ...other }: Props) {
  const { control, setValue } = useFormContext();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Check if the input is a valid number or an empty string
    if (/^\d*$/.test(inputValue)) {
      const numericValue = Number(inputValue);
      setValue(name, numericValue);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    // min max 체크
    if (typeof min === 'number' && e.target.value && Number(e.target.value) < min) {
      setValue(name, min);
    }
    if (max && e.target.value && Number(e.target.value) > max) {
      setValue(name, max);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextField
            {...field}
            name={name}
            fullWidth
            value={field.value ? field.value : ''}
            error={!!error}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={error ? error?.message : helperText}
            {...other}
          />
        );
      }}
    />
  );
}
