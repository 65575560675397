import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ReactComponent as Business2Team } from 'src/assets/organizationLayoutImage/divisionOffice/business2Team.svg';
import { ReactComponent as BusinessSupportTeam } from 'src/assets/organizationLayoutImage/divisionOffice/businessSupportTeam.svg';
import { ReactComponent as Dev2Team } from 'src/assets/organizationLayoutImage/divisionOffice/dev2Team.svg';
import { ReactComponent as FullLayoutSVG } from 'src/assets/organizationLayoutImage/divisionOffice/fullLayout.svg';
import { DialogAnimate } from 'src/components/animate';
import { Page } from 'src/components/Page';

const DivisionOffice = () => {
  const pageTitle = '자리배치도';
  const [open, setOpen] = useState(false);
  const [selectedArea, setSelectedArea] = useState('');

  // 다이얼로그 열기 및 영역 설정
  const handleOpenDialog = (areaName: string) => {
    setSelectedArea(areaName);
    setOpen(true);
  };

  // 다이얼로그 닫기
  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedArea('');
  };

  // 선택된 영역에 따라 이미지 경로 결정
  const getImage = () => {
    switch (selectedArea) {
      case 'dev2Team': // 개발 2팀
        return <Dev2Team />;
      case 'businessSupportTeam': // 경영지원팀
        return <BusinessSupportTeam />;
      case 'business2Team': // 사업 2팀
        return <Business2Team />;
      default:
        return <p>해당 영역에 대한 이미지를 찾을 수 없습니다.</p>;
    }
  };

  return (
    <Page title={pageTitle}>
      <FullLayoutSVG
        style={{ width: '100%', height: 'auto' }}
        onClick={(e) => {
          let clickedElement = e.target as HTMLElement;
          while (clickedElement && clickedElement.tagName !== 'svg') {
            if (clickedElement.id) {
              handleOpenDialog(clickedElement.id);
              break;
            }
            clickedElement = clickedElement.parentElement as HTMLElement;
          }
        }}
      />

      {open && (
        <DialogAnimate
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={handleCloseDialog}
          scroll="paper"
        >
          <div style={{ padding: 20, width: '100%' }}>{getImage()}</div>
        </DialogAnimate>
      )}
    </Page>
  );
};

export default observer(DivisionOffice);
