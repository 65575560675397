import { TDeptFormData } from 'src/@types';
import { IDepartment } from 'src/@types/apiResponseTypes';
import { IDepartmentModelSnapshot } from 'src/models/department/Department';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

const SUB_URL = '/v1/dept';

/**
 * 부서 목록 조회
 * @param deptNm 부서명
 */
export const getDepartments = async (deptNm?: string) => {
  try {
    const url = SUB_URL;
    const params = { deptNm };

    const response = await instance.get<TApiResponseData<IDepartmentModelSnapshot[]>>(url, {
      params,
    });

    // 성공 시 데이터 반환
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 부서 정보 단건조회
 * @param deptSid 부서 고유번호
 */
export const findDepartment = async (deptSid: number) => {
  const url = `${SUB_URL}/${deptSid}`;
  const data = await instance.get<{ name: string }>(url);
  return data;
};

/**
 * 부서 정보 등록
 * @param deptInfo 부서 등록 정보: TDeptFormData
 */
export const createDepartment = async (deptInfo: TDeptFormData) => {
  const url = SUB_URL;
  const payload = deptInfo;
  const response = await instance.post<TApiResponseData<IDepartment>>(url, payload);

  // 성공 시 데이터 반환
  if (response.data.resultCode === 'S' && response.data.data) {
    return {
      data: response.data.data,
    };
  }
};

/**
 * 부서 정보 수정
 * @param deptSid 부서 고유번호
 * @param deptInfo 부서 등록 정보: TDeptFormData
 */
export const updateDepartment = async (deptSid: number, deptInfo: TDeptFormData) => {
  const url = `${SUB_URL}/${deptSid}`;
  const payload = deptInfo;
  const response = await instance.put(url, payload);

  // 성공 시 데이터 반환
  if (response.data.resultCode === 'S' && response.data.data) {
    return {
      data: response.data.data,
    };
  }
};

/**
 * 부서 정보 삭제
 * @param deptSid 부서 고유번호
 */
export const deleteDepartment = async (deptSid: number) => {
  const url = `${SUB_URL}/${deptSid}`;
  const data = await instance.delete(url);
  return data;
};
