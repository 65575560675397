import { Controller, useFormContext } from 'react-hook-form';
// import { TimePickerProps } from '@mui/lab';
import { TextField, TextFieldProps } from '@mui/material';
// import { TimePicker } from '@mui/x-date-pickers';
// @mui
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { renderDigitalClockTimeView } from '@mui/x-date-pickers/timeViewRenderers';
import {
  DateTimeRangePicker,
  DateTimeRangePickerProps,
} from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import dayjs, { Dayjs } from 'dayjs';

interface RHFDatePickerProps extends DatePickerProps<Dayjs> {
  name: string;
  size?: 'medium' | 'small' | undefined;
}

export default function RHFDatePicker({ name, size = 'medium', ...other }: RHFDatePickerProps) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const date = new Date(field.value);
        const dateValue = dayjs(date).isValid() ? date : new Date();
        return (
          <DatePicker
            {...field}
            value={dayjs(dateValue)}
            onChange={(newValue) => {
              if (newValue) {
                field.onChange(dayjs(newValue).format('YYYY-MM-DD'));
              }
            }}
            slotProps={{
              textField: {
                error: Boolean(error),
                helperText: error ? error.message : undefined,
                size: size,
              },
            }}
            {...other}
          />
        );
      }}
    />
  );
}

interface RHFDateTimeRangePickerProps extends DateTimeRangePickerProps<Dayjs> {
  name: string;
}

export function RHFDateTimeRangePicker({ name, ...other }: RHFDateTimeRangePickerProps) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const startDate = field.value && field.value[0] ? new Date(field.value[0]) : new Date();
        const endDate = field.value && field.value[1] ? new Date(field.value[1]) : new Date();
        const startValue = dayjs(startDate).isValid() ? startDate : new Date();
        const endValue = dayjs(endDate).isValid() ? endDate : new Date();
        return (
          <DateTimeRangePicker
            {...field}
            value={[dayjs(startValue), dayjs(endValue)]}
            onChange={(newValue) => {
              if (typeof newValue === 'object') {
                if (newValue[0] && newValue[1]) {
                  field.onChange([dayjs(newValue[0]).toDate(), dayjs(newValue[1]).toDate()]);
                }
              }
            }}
            views={['day', 'hours']}
            timeSteps={{ minutes: 20 }}
            viewRenderers={{ hours: renderDigitalClockTimeView }}
            {...other}
          />
        );
      }}
    />
  );
}

interface RHFTimePickerProps extends TextFieldProps<'outlined'> {
  name: string;
}

export function RHFTimePicker({ name, label, ...other }: RHFTimePickerProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          {...other}
          label={label}
          type="time"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            // 입력된 시간이 유효한지 확인
            const timeValue = e.target.value;
            if (timeValue) {
              const [hours, minutes] = timeValue.split(':');
              const isValidTime =
                parseInt(hours) >= 0 &&
                parseInt(hours) < 24 &&
                parseInt(minutes) >= 0 &&
                parseInt(minutes) < 60;

              if (isValidTime) {
                field.onChange(timeValue);
              }
            }
          }}
        />
      )}
    />
  );
}
