import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { Typography } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';

interface NewChatButtonProps extends ButtonProps {}

/**
 * 새 채팅 시작 버튼 컴포넌트
 * - 새로운 채팅방 생성 기능
 * - 아이콘과 텍스트 포함
 */
function NewChatButton({ ...buttonProps }: NewChatButtonProps) {
  return (
    <Button
      sx={{
        height: '40px',
        border: '1px solid #FFF',
        borderRadius: '20px',
        pr: 3,
      }}
      component="label"
      variant="contained"
      fullWidth
      {...buttonProps}
    >
      <Typography variant="h6">
        <CommentOutlinedIcon sx={{ fontSize: 18 }} /> New Chat
      </Typography>
    </Button>
  );
}

export default NewChatButton;
