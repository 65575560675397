import './layout.css';

import { SyntheticEvent, useState } from 'react';
import { Box, styled, Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Page } from 'src/components/Page';

import DivisionOffice from './DivisionOffice';
import MainOffice from './MainOffice';

const OrganizationLayoutImage = () => {
  const pageTitle = '자리배치도';
  const tabs = [{ label: '본사' }, { label: '가산' }];
  // 활성화 탭
  const [activeTab, setActiveTab] = useState(0);

  // 탭 변경 핸들러
  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Page title={pageTitle}>
      <StyledTabs value={activeTab} onChange={handleTabChange}>
        {tabs.map((step, index) => (
          <StyledTab key={index} label={step.label} />
        ))}
      </StyledTabs>

      <StyledContsContainer>
        {tabs[activeTab].label === '본사' ? <MainOffice /> : <DivisionOffice />}
      </StyledContsContainer>
    </Page>
  );
};

export default observer(OrganizationLayoutImage);

export const StyledTabs = styled(Tabs)(() => ({
  paddingLeft: 4,
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  marginBottom: -1,
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  paddingLeft: '16px',
  paddingRight: '16px',
  color: theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightMedium,
  borderRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  backgroundColor: theme.palette.action.disabledBackground,
  marginRight: '2px !important',
  '&.Mui-selected': {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.info.dark,
  },
}));

export const StyledContsContainer = styled(Box)(({ theme }) => ({
  padding: 16,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
}));
