import 'dayjs/locale/ko';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.locale('ko');

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'YYYY-MM-DD';

  return date ? dayjs(date).format(fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'YYYY-MM-DD HH:mm:ss';

  return date ? dayjs(date).format(fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? dayjs(date).valueOf() : '';
}

export function fToNow(date: InputValue) {
  return date ? dayjs(date).fromNow() : '';
}

export function fDateToNow(date: InputValue) {
  if (!date) return '';

  const d = dayjs(date);
  const now = dayjs();
  const diff = now.diff(d, 'second');

  if (diff < 60) {
    // 1분 미만일땐 방금 전 표기
    return '방금 전';
  }

  if (diff < 60 * 60 * 24 * 3) {
    // 3일 미만일땐 시간차이 출력(몇시간 전, 몇일 전)
    return d.fromNow();
  }

  return d.format('YYYY-MM-DD HH:mm');
}
