import { flow, Instance, SnapshotOut, types } from 'mobx-state-tree';
import { commonApi } from 'src/api';

import { CodeGroupModel, DynamicCodeModel, ICodeGroupModel } from '../common-code/CommonCode';

/**
 * # CommonCodeStore
 * - 공통코드 목록을 관리하는 스토어
 */
export const CommonCodeStore = types
  .model('CommonCodeStore')
  .props({
    commonCodes: types.optional(types.array(CodeGroupModel), []),
    dynamicCodes: types.optional(types.map(types.array(DynamicCodeModel)), {}),
  })
  // 동적 코드 요청 중복 방지를 위한 변수
  .volatile((self) => ({
    fetchingCodes: new Map<string, Promise<any>>(),
  }))
  .views((self) => ({
    getCCodeByName(name: string) {
      return self.commonCodes.find((item) => item.name === name)?.list || [];
    },
  }))
  .actions((self) => ({
    setCommonCodes: (commonCodes: ICodeGroupModel[]) => {
      self.commonCodes.replace(commonCodes);
    },
  }))
  .actions((self) => ({
    fetchDynamicCodes: flow(function* (code: number) {
      const codeStr = String(code);

      // 이미 진행 중인 요청이 있는 경우 무시
      if (self.fetchingCodes.has(codeStr)) {
        return;
      }

      const promise = commonApi.getDynamicCd(code);
      self.fetchingCodes.set(codeStr, promise);

      try {
        const result = yield promise;
        if (result) {
          self.dynamicCodes.set(codeStr, result);
        }
      } finally {
        self.fetchingCodes.delete(codeStr);
      }
    }),
  }))
  .views((self) => ({
    // 동적 코드 조회
    getDynamicCodesByCode(code: number) {
      // 해당 값이 없으면 api 요청
      if (!self.dynamicCodes.get(String(code))) {
        self.fetchDynamicCodes(code);
      }

      return self.dynamicCodes.get(String(code)) || [];
    },
  }))
  .actions((self) => ({
    /**
     * 공통코드 조회
     */
    getCommonCodes: async () => {
      const result = await commonApi.getCommonCodes();
      if (result) {
        self.setCommonCodes(result);
      }
    },
  }))
  .actions((self) => ({
    // 모델 생성 시 공통코드 조회
    afterCreate() {
      self.getCommonCodes();
    },
  }));

// --------------------------------------------------------------------------
type TCommonCodeStore = Instance<typeof CommonCodeStore>;
type TCommonCodeStoreSnapshot = SnapshotOut<typeof CommonCodeStore>;

export interface ICommonCodeStore extends TCommonCodeStore {}
export type TCommonCodeStoreKeys = keyof TCommonCodeStoreSnapshot & string;
export interface ICommonCodeStoreSnapshot extends TCommonCodeStoreSnapshot {}
export const createCommonCodeStore = () =>
  types.optional(CommonCodeStore, {
    commonCodes: [],
    dynamicCodes: {},
  });
