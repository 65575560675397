import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useStores } from 'src/models';

// 사용자 기본 정보 컴포넌트
const BasicUserInfo = observer(() => {
  const { authStore, departmentStore } = useStores();
  const { user } = authStore;
  const { departments } = departmentStore;
  // 사용자 정보 조회
  const userName = user?.userNm || '';
  const dept = departments.find((dept) => dept.deptSid === user?.deptSid)?.deptNm || '';
  const job = user?.empJobrespCd?.value || '';
  const pos = user?.empPosCd?.value || '';

  return (
    <>
      <TextField defaultValue={user?.loginId || ''} label="아이디" disabled />
      <Stack
        direction={'row'}
        spacing={2}
        sx={{
          '& > *': {
            flex: 1,
          },
        }}
      >
        <TextField value={userName} label="이름" disabled />
        <TextField value={dept} label="부서" disabled />
        <TextField value={job} label="직책" disabled />
        <TextField value={pos} label="직급" disabled />
      </Stack>
    </>
  );
});

export default BasicUserInfo;
