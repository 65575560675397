import 'dayjs/locale/ko';

import React, { forwardRef, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Divider,
  IconButton,
  List,
  ListSubheader,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { ChatbotFAQ } from 'src/@types/apiResponseTypes';

import { SIDEBAR_BACKGROUND_COLOR } from '../../utils/contants';
import {
  ChatList,
  CurrentChatRoomIdState,
  isChatListTypeArray,
  OnDeleteChatRoom,
} from '../../utils/types';

import ChatListItem from './ChatListItem';

dayjs.locale('ko');

/**
 * 채팅 목록을 그룹화하여 보여주는 컴포넌트
 * - 채팅방을 날짜별로 그룹화
 * - 채팅 목록 헤더와 삭제 기능 제공
 * - 스크롤 가능한 채팅 목록 컨테이너
 */
interface ChatListItemBoxProps {
  /** 섹션 제목과 아이콘 정보 */
  subtitle: {
    text: string;
    icon?: React.ReactNode;
  };
  /** 채팅방 목록 데이터 */
  chatRooms: ChatList[] | ChatbotFAQ[];
  /** 현재 선택된 채팅방 상태 관리 */
  currentChatRoomIdState: CurrentChatRoomIdState;
  /** 모바일 드로어 닫기 함수 */
  onCloseDrawer?: () => void;
  /** 채팅방 삭제 관련 함수들 */
  onDeleteChatRoom?: OnDeleteChatRoom;
}

/** 날짜별로 그룹화된 채팅방 데이터 타입 */
interface GroupedChatRooms {
  [key: string]: ChatList[];
}

const ChatListItemBox = forwardRef<HTMLLIElement, ChatListItemBoxProps>(
  (
    { subtitle, chatRooms, currentChatRoomIdState, onCloseDrawer, onDeleteChatRoom },
    lastChatElementRef,
  ) => {
    const [removeAllChatYn, setRemoveAllChatYn] = useState<boolean>(false);

    const renderChatRooms = () => {
      if (isChatListTypeArray(chatRooms)) {
        const groupedChatRooms = groupChatRoomsByDate(chatRooms);
        const sortedGroupKeys = sortGroupKeys(Object.keys(groupedChatRooms));

        return sortedGroupKeys.map((group) =>
          groupedChatRooms[group].length > 0 ? (
            <React.Fragment key={group}>
              <ListSubheader sx={{ backgroundColor: SIDEBAR_BACKGROUND_COLOR, px: 1 }}>
                <Typography variant="body2" color="#FFF" fontWeight="600" sx={{ p: 0 }}>
                  {group}
                </Typography>
              </ListSubheader>
              {groupedChatRooms[group].map((chatRoom, index) => (
                <ChatListItem
                  ref={lastChatElementRef}
                  key={chatRoom.reg_dt + index}
                  chatRoom={chatRoom}
                  currentChatRoomIdState={currentChatRoomIdState}
                  onDeleteSingleChatRoom={onDeleteSingleChatRoom}
                  onCloseDrawer={onCloseDrawer}
                />
              ))}
            </React.Fragment>
          ) : null,
        );
      } else {
        return (
          chatRooms.length > 0 &&
          chatRooms.map((chatRoom) => (
            <ChatListItem
              key={chatRoom.reg_dt}
              chatRoom={chatRoom}
              currentChatRoomIdState={currentChatRoomIdState}
              onCloseDrawer={onCloseDrawer}
            />
          ))
        );
      }
    };

    const { text, icon } = subtitle;
    const onDeleteAllChatRooms = onDeleteChatRoom?.onDeleteAllChatRooms;
    const onDeleteSingleChatRoom = onDeleteChatRoom?.onDeleteSingleChatRoom;

    return (
      <Stack justifyContent={'flex-start'} sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '6px 10px',
          }}
        >
          <StyledTypography variant="caption">{text}</StyledTypography>
          <IconButton
            size="small"
            sx={{ padding: 0 }}
            onClick={() => {
              setRemoveAllChatYn(true);
            }}
          >
            {removeAllChatYn ? (
              <>
                <CheckIcon
                  sx={{ fontSize: 18, color: 'white' }}
                  onClick={(event) => {
                    event.stopPropagation();
                    onDeleteAllChatRooms && onDeleteAllChatRooms();
                    setRemoveAllChatYn(false);
                  }}
                />
                <CloseIcon
                  sx={{ fontSize: 18, color: 'white' }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setRemoveAllChatYn(false);
                  }}
                />
              </>
            ) : (
              <>{icon}</>
            )}
          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            maxHeight: 'calc(50vh - 170px)',
            overflowY: 'auto',
            paddingX: '4px',
            '&::-webkit-scrollbar': {
              background: '#FFF5',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#FFF5',
            },
            pb: 1,
          }}
        >
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: ' 3px',
              paddingTop: 0,
            }}
          >
            {renderChatRooms()}
          </List>
        </Box>
        <Divider />
      </Stack>
    );
  },
);

const groupChatRoomsByDate = (rooms: ChatList[]): GroupedChatRooms => {
  const grouped: GroupedChatRooms = {};
  const today = dayjs();

  rooms.forEach((room) => {
    // 날짜를 변환할 떄 day.js가 인식할 수 있는 형태로 변환
    const roomDate = dayjs(room.reg_dt);
    // 일 차이 계산
    const diffDays = today.diff(roomDate, 'day');
    // 연 차이 계산
    const diffYears = today.diff(roomDate, 'year');

    let groupKey: string;

    if (diffDays === 0) {
      groupKey = '오늘';
    } else if (diffDays === 1) {
      groupKey = '어제';
    } else if (diffDays <= 7) {
      groupKey = '지난 7일';
    } else if (diffDays <= 30) {
      groupKey = '지난 30일';
    } else if (diffYears === 0) {
      groupKey = roomDate.format('M월');
    } else {
      groupKey = roomDate.format('YYYY년');
    }

    // grouped 오브젝트에 groupKey에 해당하지 않은 value값이 없으면 undefined 대신 빈 객체로 초기화 한다.
    // 빈 배열로 초기화 하지 않으면 undefined.push()를 호출하게 되어 에러를 발생시킨다.
    if (!grouped[groupKey]) {
      grouped[groupKey] = [];
    }
    grouped[groupKey].push(room);
  });

  return grouped;
};

// '오늘', '어제', '지난 7일', '지난 30일' 순으로 날짜 라벨 정렬
const sortGroupKeys = (keys: string[]): string[] => {
  const orderPriority: { [key: string]: number } = {
    오늘: 1,
    어제: 2,
    '지난 7일': 3,
    '지난 30일': 4,
  };

  const monthOrder: { [key: string]: number } = {
    '1월': 1,
    '2월': 2,
    '3월': 3,
    '4월': 4,
    '5월': 5,
    '6월': 6,
    '7월': 7,
    '8월': 8,
    '9월': 9,
    '10월': 10,
    '11월': 11,
    '12월': 12,
  };

  return keys.sort((a, b) => {
    // 우선순위가 정의된 키 처리
    if (orderPriority[a] !== undefined && orderPriority[b] !== undefined) {
      return orderPriority[a] - orderPriority[b];
    }
    if (orderPriority[a] !== undefined) return -1;
    if (orderPriority[b] !== undefined) return 1;

    // 월 비교
    const monthA = monthOrder[a];
    const monthB = monthOrder[b];
    if (monthA !== undefined && monthB !== undefined) {
      return monthB - monthA; // 월을 역순으로 정렬
    }
    if (monthA !== undefined) return -1;
    if (monthB !== undefined) return 1;

    // 년도 비교
    const yearA = a.match(/(\d+)년/);
    const yearB = b.match(/(\d+)년/);
    if (yearA && yearB) {
      return parseInt(yearB[1]) - parseInt(yearA[1]);
    }
    if (yearA) return -1;
    if (yearB) return 1;

    // 기본 문자열 비교
    return b.localeCompare(a);
  });
};

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 800,
  fontSize: '14px',
  lineHeight: '30px',
  color: '#FFF8',
}));

export default ChatListItemBox;
