import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ReactComponent as AIDevTeam } from 'src/assets/organizationLayoutImage/AIDevTeam.svg';
import { ReactComponent as Business1Team } from 'src/assets/organizationLayoutImage/business1Team.svg';
import { ReactComponent as Business2Team } from 'src/assets/organizationLayoutImage/business2Team.svg';
import { ReactComponent as CEO } from 'src/assets/organizationLayoutImage/CEO.svg';
import { ReactComponent as Dev1_4Team } from 'src/assets/organizationLayoutImage/dev1_4Team.svg';
import { ReactComponent as Dev1Team } from 'src/assets/organizationLayoutImage/dev1Team.svg';
import { ReactComponent as ExecutiveOffice } from 'src/assets/organizationLayoutImage/executiveOffice.svg';
import { ReactComponent as FullLayoutSVG } from 'src/assets/organizationLayoutImage/fullLayout.svg';
import { ReactComponent as RegulatoryTeam } from 'src/assets/organizationLayoutImage/regulatoryTeam.svg';
import { ReactComponent as SeniorExecOffice } from 'src/assets/organizationLayoutImage/seniorExecOffice.svg';
import { ReactComponent as ServiceDevTeam } from 'src/assets/organizationLayoutImage/serviceDevTeam.svg';
import { DialogAnimate } from 'src/components/animate';
import { Page } from 'src/components/Page';

const MainOffice = () => {
  const pageTitle = '자리배치도';
  const [open, setOpen] = useState(false);
  const [selectedArea, setSelectedArea] = useState('');

  // 다이얼로그 열기 및 영역 설정
  const handleOpenDialog = (areaName: string) => {
    console.log(areaName);

    setSelectedArea(areaName);
    setOpen(true);
  };

  // 다이얼로그 닫기
  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedArea('');
  };

  // 선택된 영역에 따라 이미지 경로 결정
  const getImage = () => {
    switch (selectedArea) {
      case 'ceo': // 대표이사실
        return <CEO />;
      case 'executiveOffice': // 상무이사실
        return <ExecutiveOffice />;
      case 'seniorExecOffice': // 전무이사실
        return <SeniorExecOffice />;
      case 'AIDevTeam': // ai 연구소
        return <AIDevTeam />;
      case 'regulatoryTeam': // 인허가팀
        return <RegulatoryTeam />;
      case 'serviceDevTeam': // 서비스 개발팀
        return <ServiceDevTeam />;
      case 'dev1_4Team': // 개발1,4팀
        return <Dev1_4Team />;
      case 'dev1Team': // 개발 1팀
        return <Dev1Team />;
      case 'business1Team': // 사업 1팀
        return <Business1Team />;
      case 'business2Team': // 사업 2팀
        return <Business2Team />;
      default:
        return <p>해당 영역에 대한 이미지를 찾을 수 없습니다.</p>;
    }
  };

  return (
    <Page title={pageTitle}>
      <FullLayoutSVG
        style={{ width: '100%', height: 'auto' }}
        onClick={(e) => {
          let clickedElement = e.target as HTMLElement;
          while (clickedElement && clickedElement.tagName !== 'svg') {
            if (clickedElement.id) {
              console.log(clickedElement.id);

              handleOpenDialog(clickedElement.id);
              break;
            }
            clickedElement = clickedElement.parentElement as HTMLElement;
          }
        }}
      />

      {open && (
        <DialogAnimate
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={handleCloseDialog}
          scroll="paper"
        >
          {/* <div style={{ padding: 20, width: '100%' }}>{getImage()}</div> */}
          <div style={{ padding: 20, width: '100%', height: '100%' }}>{getImage()}</div>
        </DialogAnimate>
      )}
    </Page>
  );
};

export default observer(MainOffice);
