/**
 * MIME 타입에서 확장자를 추출하는 함수
 */
export const getMimeTypeExtension = (mimeType: string): string => {
  const mimeToExtMap: Record<string, string> = {
    // 이미지
    'image/jpeg': 'jpg',
    'image/jpg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/bmp': 'bmp',
    'image/webp': 'webp',
    'image/svg+xml': 'svg',
    'image/tiff': 'tiff',
    // 문서
    'application/pdf': 'pdf',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    // 압축
    'application/zip': 'zip',
    'application/x-rar-compressed': 'rar',
    'application/x-7z-compressed': '7z',
    // 텍스트
    'text/plain': 'txt',
    'text/html': 'html',
    'text/css': 'css',
    'text/javascript': 'js',
    // 오디오
    'audio/mpeg': 'mp3',
    'audio/wav': 'wav',
    'audio/ogg': 'ogg',
    // 비디오
    'video/mp4': 'mp4',
    'video/webm': 'webm',
    'video/ogg': 'ogv',
  };

  // MIME 타입에서 서브타입 추출 (예: "image/png" -> "png")
  const subtype = mimeType.split('/')[1];

  // 정확한 MIME 타입 매칭
  if (mimeToExtMap[mimeType]) {
    return mimeToExtMap[mimeType];
  }

  // 서브타입이 확장자로 사용가능한 경우
  if (/^[a-zA-Z0-9]+$/.test(subtype) && !subtype.includes('x-')) {
    return subtype;
  }

  return 'bin';
};
