import { useCallback, useState } from 'react';
import { chatbotApi } from 'src/api';

import { ChatbotHistoryResponse, ChatListRequest, ConversationType } from '../utils/types';

/**
 * 채팅 응답 관련 커스텀 훅
 * - 채팅 목록 조회
 * - 채팅 히스토리 조회
 * - 채팅방 삭제 기능
 */
function useChatResponse() {
  // 채팅 히스토리 응답 데이터 상태
  const [chatbotHistoryResponse, setChatbotHistoryResponse] = useState<
    ChatbotHistoryResponse | undefined
  >(undefined);

  // 현재 대화 내용 상태
  const [conversations, setConversations] = useState<ConversationType[] | undefined>(undefined);

  // 채팅 목록 조회 함수
  const getChatList = useCallback(async (chatListRequest: ChatListRequest) => {
    try {
      const data = await chatbotApi.getChatList(chatListRequest);
      if (data) {
        return data.chat_list;
      }
      return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  }, []);

  // 특정 채팅방의 대화 내역 조회 함수
  const findChatHistory = async ({ chat_id }: { chat_id: string }) => {
    try {
      const data = await chatbotApi.findChatHistory({ chat_id });
      if (data) {
        // API 응답 데이터를 ConversationType 배열로 변환
        const newConversation: ConversationType[] = data.history.map(
          ({ ord, user_id, user_input, response, reg_dt, is_faq }) => ({
            aiResponse: { chat_id, user_id, response, ord, reg_dt, is_faq },
            userMessage: { user_id, user_input, reg_dt },
          }),
        );
        setConversations(newConversation);
        setChatbotHistoryResponse(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 단일 채팅방 삭제 함수
  const deleteChat = async ({ chat_id }: { chat_id: string }) => {
    try {
      const data = await chatbotApi.deleteChat({ chat_id });
      if (data) {
        setChatbotHistoryResponse(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 사용자의 모든 채팅방 삭제 함수
  const deleteAllChats = async (user_id: number) => {
    try {
      const data = await chatbotApi.deleteAllChat(user_id);
      if (data) {
        setChatbotHistoryResponse(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 필요한 상태와 함수들을 객체로 그룹화하여 반환
  return {
    getChatList,
    history: { chatbotHistoryResponse, setChatbotHistoryResponse, findChatHistory },
    conv: { conversations, setConversations },
    onDeleteChatRoom: {
      deleteChat,
      deleteAllChats,
    },
  };
}

export default useChatResponse;
